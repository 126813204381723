import randomString from '@/assets/js/random-string.js';
import session from './session';
import {createStore} from 'vuex';
import wretch from 'wretch';

export const store = createStore({
  state() {
    return {
      firstLoad: true,
      view: 'none',
      authorisation: {
        blocked: false
      },
      settings: {
        foo: 'bar'
      },
      profile: {
        name: null,
        role: null,
        image: {},
        menu: []
      },
      menu: [],
      toolbar: {
        previous: null,
        toggleable: null,
        previewable: null,
        removable: null,
        breadcrumb: []
      },
      content: {
        layout: '1fr',
        modules: []
      },
      modal: null,
      toasts: []
    };
  },
  mutations: {
    AUTH(state, data = {}) {
      let {view, blocked, settings} = data;

      // Update state
      if (settings) state.settings = settings;
      if (view) state.view = view;
      if (blocked) state.authorisation.blocked = blocked;
    },
    UPDATE_PROFILE(state, data = {}) {
      let {name, role, image, menu} = data;

      // Update state
      state.profile.name = name;
      state.profile.role = role;
      state.profile.image = image;
      state.profile.menu = menu;
    },
    UPDATE_TOOLBAR(state, data = {}) {
      let {previous, toggleable, previewable, removable, breadcrumb} = data;

      // Update state
      state.toolbar.previous = previous;
      state.toolbar.toggleable = toggleable;
      state.toolbar.previewable = previewable;
      state.toolbar.removable = removable;
      state.toolbar.breadcrumb = breadcrumb;
    },
    UPDATE_MENU(state, data = []) {
      state.menu = data;
    },
    UPDATE_CONTENT(state, data = {}) {
      let {layout, modules} = data;

      // Update state
      state.content.layout = layout || '1fr';
      state.content.modules = modules || [];
    },
    LOAD(state) {
      state.loading = true;
    },
    COMPLETE(state) {
      state.loading = false;

      // If false, set firstLoad to true
      if (!state.firstLoad) state.firstLoad = true;
    },
    ADD_TOAST(state, data) {
      state.toasts.push({...data, ...{id: randomString()}});
    },
    REMOVE_TOAST(state, index) {
      try {
        state.toasts.splice(index, 1);
      }
      catch(e) {
        console.error('No notification exists at index', index)
      }
    },
    SHOW_MODAL(state, data) {
      state.modal = data
    },
    HIDE_MODAL(state) {
      state.modal = null
    }
  },
  actions: {
    pop({commit}, event) {
      console.log('Change history state...', event, commit);
    },
    fetch({commit}, data = {}) {
      commit('LOAD');

      // Fetch relevant data
      wretch(`https://api.motion.wearegrowth.com${data.url || ''}`)
        .headers({
          Authorization: session.authorisation
        })
        .post(data.body)
        .json(response => {
          commit('COMPLETE');

          // Parse data
          let {view, blocked, settings, profile, toolbar, menu, main, token} = response;

          // Save session token
          if (token) session.authorisation = `Basic ${token}`;

          // Update core settings
          commit('AUTH', {view, blocked, settings});

          // Update module states
          if (profile) commit('UPDATE_PROFILE', profile);
          if (toolbar) commit('UPDATE_TOOLBAR', toolbar);
          if (menu) commit('UPDATE_MENU', menu);
          if (main) commit('UPDATE_CONTENT', main);
        });
    },
    toast({commit}, data = null) {
      if(!data?.content) return;

      // Create notification
      commit('ADD_TOAST', data);
    },
    removeToast({commit}, id = null) {
      if(!id) return;

      // Create notification
      commit('REMOVE_TOAST', id);
    },
    modal({commit}, data = null) {
      if(!data) return;

      // Create notification
      commit('SHOW_MODAL', data);
    },
    hideModal({commit}) {
      commit('HIDE_MODAL');
    },
  }
});
