// Type input
export default {
  name: 'listed',
  label: 'How should this appear in listings?',
  type: 'dropdown',
  value: false,
  options: [
    {
      value: false,
      text: 'Do not show in listings',
      selected: true
    },
    {
      value: 'id',
      text: 'Primary text (ID)'
    },
    {
      value: 'text',
      text: 'Text'
    },
    {
      value: 'range',
      text: 'Range'
    },
    {
      value: 'number',
      text: 'Number'
    }
  ]
};
