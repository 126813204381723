import {
  checked,
  name,
  label,
  type,
  value,
  placeholder,
  min,
  max,
  step,
  classname,
  required,
  hidden,
  wysiwyg,
  disabled,
  pattern,
  maxlength,
  listed,
  sortlist,
  options,
  conditions
} from './inputs/_';

/**
 *  Default form options
 * 
 */
let autobuilder = {
  type,
  name,
  label,
  value,
  placeholder,
  classname

  /* 
  tooltip: '',
  unique: false,
  
  media: [], // conditional [image, video, ...]
  join: '', // conditional [db, ...]
  allconditions: true,
  role: []
  */
};

// Groups
let groups = [
  {
    type: 'grid',
    title: 'Number formatting',
    details: {
      gridsize: 3
    },
    contentDefaults: {min, max, step}
  },
  {
    type: 'grid',
    title: 'Text formatting',
    contentDefaults: {
      pattern,
      maxlength,
      wysiwyg
    }
  },
  {
    type: 'grid',
    title: 'Dropdown options',
    contentDefaults: {
      options
    }
  },
  {
    type: 'grid',
    title: 'Additional options',
    contentDefaults: {
      checked,
      required,
      hidden,
      disabled
    }
  },
  {
    type: 'grid',
    title: 'Image options',
    contentDefaults: {}
  },
  {
    type: 'grid',
    title: 'Conditions',
    contentDefaults: {
      conditions
    }
  },
  {
    type: 'grid',
    title: 'List view options',
    contentDefaults: {
      listed,
      sortlist
    }
  }
];

/**
 *  Encode values to autobuilder
 *  
 *  @param {Object} overrides
 */
let encodeAutobuilder = (overrides = {}) => {
  let form = [];

  // Build formbuilder root
  for (let [key, defaults] of Object.entries(autobuilder)) {
    let userData = overrides[key] ? {value: overrides[key]} : {};

    form.push({
      type: 'element',
      details: {...defaults, ...userData}
    });
  }

  // Loop through groups, compile these
  groups.forEach(group => {
    let groupForm = [];
    let contentDefaults = group.contentDefaults || {};

    // Build formbuilder group
    for (let [key, defaults] of Object.entries(contentDefaults)) {
      let userData = overrides[key] ? {value: overrides[key]} : {};

      groupForm.push({
        type: 'element',
        details: {...defaults, ...userData}
      });
    }

    // Add to form
    form.push({...group, ...{content: groupForm}});
  });

  // Return form
  return form;
};

// Export default options
export default encodeAutobuilder;

// Export raw inputs, for testing
export {groups, autobuilder};
