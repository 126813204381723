<template>
  <div class="form__label" :class="[labelClass, details.classname]">
    {{ details.label }}

    <fieldset class="autobuilder__options">
      <legend :id="uniqueId">Dropdown options</legend>

      <ul class="autobuilder__options__list" v-if="value.length">
        <li class="autobuilder__options__listitem autobuilder__options__grid">
          <span class="autobuilder__options__label" :id="label + 1"
            >Option value</span
          >
          <span class="autobuilder__options__label" :id="label + 2"
            >Option text</span
          >
          <span
            class="autobuilder__options__label autobuilder__options__label--double-col"
            :id="label + 3"
            >Selected</span
          >
        </li>
        <li
          class="autobuilder__options__listitem"
          v-for="(option, index) of value"
          :key="index"
        >
          <fieldset
            class="autobuilder__options__fieldset"
            :id="fieldset + index"
          >
            <legend>Option row</legend>

            <input
              class="autobuilder__options__input autobuilder__options__input--value"
              :aria-labelledby="label + 1"
              placeholder="Value"
              type="text"
              v-model="option.value"
              required
            />

            <input
              class="autobuilder__options__input autobuilder__options__input--text"
              :aria-labelledby="label + 2"
              placeholder="Text"
              type="text"
              v-model="option.text"
              required
            />

            <InputCheckbox
              role="radio"
              class="autobuilder__options__input autobuilder__options__input--checkbox"
              :tabindex="tabindex === index ? 0 : -1"
              :value="index"
              :checked="Boolean(option.selected)"
              :aria-labelledby="label + 3"
              @input="(checked) => (selected = checked ? index : -1)"
              @keydown.up.prevent="skipToRadio(index - 1)"
              @keydown.down.prevent="skipToRadio(index + 1)"
              :ref="
                (el) => {
                  radiobuttons[index] = el;
                }
              "
            />

            <button
              type="button"
              class="autobuilder__options__delete"
              @click.prevent.stop="removeOption(index)"
              aria-label="Delete this row"
              title="Delete this row"
              :aria-controls="fieldset + index"
            >
              <SvgIcon
                name="cross"
                title="Delete icon"
                class="autobuilder__options__delete__icon"
              />
            </button>
          </fieldset>
        </li>
      </ul>

      <button
        type="button"
        class="autobuilder__options__add-button"
        :aria-describedby="uniqueId"
        @click.prevent="addOption"
      >
        Add option
      </button>
    </fieldset>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon.vue";
import InputCheckbox from "@/components/ui/InputCheckbox.vue";
import defaultData from "../__mixins.js";
import uniqueId from "@/mixins/unique-id.js";
import { ref, onBeforeUpdate } from "vue";

export default {
  name: "FormOptions",
  components: {
    SvgIcon,
    InputCheckbox,
  },
  mixins: [defaultData, uniqueId],
  data() {
    let id = this.uniqueId;

    return {
      selected: this.value?.findIndex((n) => n.selected),
      fieldset: id + `-fieldset-`,
      label: id + `-label-`,
    };
  },
  methods: {
    addOption() {
      if (!Array.isArray(this.value)) this.value = [];

      this.value.push({ text: "", value: "", selected: false });
    },
    removeOption(index = -1) {
      this.value.splice(index, 1);
    },
    skipToRadio(index) {
      let bounds = this.radiobuttons.length;

      // Ensure index is within bounds
      if (index < 0) index = bounds - 1;
      if (index >= bounds) index = 0;

      // Update selected index
      this.selected = index;

      // Focus on new index
      this.radiobuttons[index]?.$el?.focus();
    },
  },
  computed: {
    tabindex() {
      return Math.max(this.selected, 0);
    },
  },
  watch: {
    selected() {
      this.value.forEach((option, index) => {
        option.selected = index === this.selected;
      });
    },
  },
  setup() {
    const radiobuttons = ref([]);

    // make sure to reset the refs before each update
    onBeforeUpdate(() => {
      radiobuttons.value = [];
    });

    return {
      radiobuttons,
    };
  },
};
</script>

<style lang="scss">
.autobuilder__options {
  &__list {
    list-style: none;
    margin: 1em 0;
    padding: 0;
  }

  &__listitem {
    padding: 0.2em 0;
  }

  &__label {
    font-size: 0.72em;
    line-height: 1.4em;
    text-transform: uppercase;
    font-weight: 500;

    &--double-col {
      grid-column: span 2;
    }
  }

  &__fieldset {
    position: relative;
    border-radius: var(--input-radius);
    background: var(--input-bg);
  }

  &__fieldset,
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 3em 2.5em;
    align-items: center;
    justify-content: flex-start;
  }

  &__delete {
    width: 1em;
    height: 1em;
    font-size: 1.4em;
    text-align: center;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    color: var(--light-text);
    background: transparent;
    transition: color 0.15s;

    &__icon {
      display: block;
      margin: auto;
      width: 0.6em;
      height: 0.6em;
      stroke: currentColor;
      stroke-width: 5px;
      fill: transparent;
      stroke-linejoin: round;
      stroke-linecap: round;
    }

    &:hover {
      color: var(--input-error);
    }
  }

  &__input {
    background: transparent;
    margin: 0;

    &--value {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--checkbox {
      margin-left: 1.2rem;
    }
  }

  &__add-button {
    display: block;
    width: 100%;
    text-align: center;
    margin: 1.5em 0 0;
  }
}
</style>