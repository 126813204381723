<template>
  <div
    class="formbuilder__group"
    :class="{ 'formbuilder__group--draggable': nested }"
  >
    <h2
      v-if="nested"
      class="formbuilder__group__title"
      contenteditable
      @blur="editTitle"
      @paste.prevent.stop="pasteTitle"
    >
      {{ title }}
    </h2>

    <SettingsPopup
      v-if="nested"
      :options="settingsOptions"
      :deletable="true"
      class="formbuilder__group__settings"
      @option-updated="optionUpdated"
      @delete-component="deleteGroup"
    />

    <VueDraggableNext
      :style="{ gridTemplateColumns }"
      :list="content"
      :group="'formbuilder'"
      :handle="'.draggable'"
      class="formbuilder__dragzone"
      @change="() => emitUpdate(false)"
      @end="() => emitUpdate()"
    >
      <component
        :is="componentType(item)"
        v-for="item of content"
        :key="item.name"
        :contentProp="item.content"
        :detailsProp="item.details"
        :titleProp="item.title"
        @content-updated="(e) => contentUpdated(item, e)"
        @update-title="(e) => updateTitle(item, e)"
        @delete-item="(e) => deleteItem(item, e)"
      ></component>
    </VueDraggableNext>

    <FormbuilderThumb v-if="nested" />
  </div>
</template>

<script>
import FormbuilderItem from "./FormbuilderItem.vue";
import FormbuilderThumb from "./FormbuilderThumb.vue";
import SettingsPopup from "@/components/ui/SettingsPopup.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { gridsizeDefaults, classnameDefaults } from "./group-defaults/";

export default {
  name: "FormbuilderGroup",
  props: {
    contentProp: {
      type: Array,
      default: () => [],
    },
    detailsProp: {
      type: Object,
      default: () => {},
    },
    titleProp: {
      type: String,
      default: () => "",
    },
    nested: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FormbuilderItem,
    SettingsPopup,
    VueDraggableNext,
    FormbuilderThumb,
  },
  computed: {
    title() {
      return this.titleProp;
    },
    content() {
      return [...this.contentProp];
    },
    details() {
      return { ...this.detailsProp };
    },
    gridTemplateColumns() {
      return `repeat(${this.details?.gridsize}, 1fr)`;
    },
    gridsizeObject() {
      return gridsizeDefaults(this.details?.gridsize);
    },
    classnameObject() {
      return classnameDefaults(this.details?.classname);
    },
    settingsOptions() {
      return [this.gridsizeObject, this.classnameObject].filter((n) => n);
    },
  },
  methods: {
    componentType(el) {
      return el.type === "grid" ? "FormbuilderGroup" : "FormbuilderItem";
    },
    editTitle(e) {
      this.$emit("update-title", e.target.innerText);
    },
    pasteTitle(e) {
      this.$emit("update-title", e.clipboardData.getData("Text"));
    },
    updateTitle(item, title = "") {
      // 1. remove line breaks; 2. trim duplicate spaces; 3. trim start/end
      item.title = title
        .replace(/\r?\n|\r/g, " ")
        .replace(/\s{2,}/g, " ")
        .trim();

      // Emit event
      this.emitUpdate();
    },
    deleteGroup() {
      this.$emit(
        "delete-item",
        "Are you sure you want to delete this group, and any associated rows?"
      );
    },
    deleteItem(item, question = null) {
      this.deleteAction(question, () => {
        this.content.splice(this.content.indexOf(item), 1);

        // Emit event
        this.emitUpdate();
      });
    },
    deleteAction(question, deleteFunction) {
      new Promise((resolve) => {
        this.$store.dispatch("modal", {
          type: "confirm",
          content: question,
          resolve: resolve,
          buttons: {
            confirm: "Delete",
            deny: "Cancel",
          },
        });
      }).then((confirmed) => {
        if (!confirmed) return;

        deleteFunction();
      });
    },
    optionUpdated(e) {
      let { model, value } = e;

      // Update corresponding model
      this.details[model] = value;

      // Emit event
      this.emitUpdate();
    },
    contentUpdated(item, e) {
      let { content, details, title, updateserver } = e;

      // Update props for item
      item.content = content;
      item.details = details;
      item.title = title;

      // Throw event
      this.emitUpdate(updateserver);
    },
    emitUpdate(updateserver = true) {
      let { content, details, title } = this;

      this.$emit("content-updated", { content, details, title, updateserver });
    },
  },
};
</script>

<style lang="scss">
.formbuilder {
  &__group {
    position: relative;

    &--draggable {
      padding: 0 0 0 1.6em;
      margin: 1em 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      & + & {
        margin-top: 0;
      }
    }

    &__title {
      font-size: 1rem;
      line-height: 1.2em;
      margin: 0 0 0.7em;
      width: calc(100% - 1.4em);

      &:empty:before {
        content: "Enter optional title here";
        color: var(--faded-text);
      }
    }

    &__settings {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__dragzone {
    padding: 0.6em;
    border: 1px dashed var(--faded-text);
    border-radius: 0.8em;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.6em;
    min-height: 8em;
    align-items: flex-start;
  }
}
</style>