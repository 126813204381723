<template>
  <div class="listings__wrapper">
    <h1>{{ title }}</h1>
    <p>{{ description }}</p>

    <ListingsManager
      :settings="table?.settings"
      :selected-cells="selectedCells"
      @run-search="tableSearched"
      @batch-actions="batchAction"
      @add-row="addRow"
    />
    <ListingsTable
      :settingsProp="table.settings"
      :theadProp="table.thead"
      :tbodyProp="table.tbody"
      @row-checked="rowChecked"
      @row-toggled="rowToggled"
      @row-action="rowAction"
      @sort-action="sortAction"
      ref="table"
    />

    <button
      class="listings__add-new listings__add-new--full"
      @click.prevent="addRow"
      aria-label="Add new row to table"
    >
      Add new row
    </button>
  </div>
</template>

<script>
import ListingsManager from "./manager.vue";
import ListingsTable from "./table.vue";
import postData from "@/mixins/post.js";

export default {
  name: "DashboardListings",
  mixins: [postData],
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ListingsManager,
    ListingsTable,
  },
  data() {
    let { title, description, table } = this.content;

    return {
      title,
      description,
      table,
      selectedCells: [],
    };
  },
  computed: {
    checkedRows() {
      return this.$refs.table.checkedRows.map((row) => row.rowId);
    },
  },
  methods: {
    rowChecked(cells) {
      this.selectedCells = cells;
    },
    rowToggled(e) {
      let { row, active } = e;

      // Prepare data for post
      this.prepare("toggle", { active }, [row]);
    },
    tableSearched(e) {
      let { action, query } = e;

      // Prepare data for post
      this.prepare(action, { query });
    },
    sortAction(rows) {
      this.prepare("sort", {}, rows);
    },
    batchAction(e) {
      this.tableAction(e, this.checkedRows);
    },
    rowAction(e) {
      this.tableAction(e, [e.row]);
    },
    tableAction(e, rows) {
      let { action, url, confirm } = e;

      // If no confirmation is required, immediately post
      if (!confirm) {
        this.prepare(action, { url }, rows);

        return;
      }

      // Else confirm before posting
      new Promise((resolve) => {
        this.$store.dispatch("modal", {
          type: "confirm",
          content: confirm?.question || "Please confirm before proceeding",
          resolve: resolve,
          buttons: confirm?.buttons,
        });
      }).then((confirmed) => {
        if (confirmed) this.prepare(action, { url }, rows);
      });
    },
    addRow() {
      this.prepare("add");
    },
    prepare(action, data = {}, rows = null) {
      // Combine data and row information
      let postData = this.clean({ ...data, rows });

      // Post data to server
      this.postToServer("/listings/foo/bar/", {
        action,
        postData,
      });

      // If action is 'delete', delete appropriate rows
      if (action !== "delete") return;

      // Loop through rows to filter as appropriate
      rows.forEach((id) => {
        this.table.tbody = this.table.tbody.filter((row) => row.rowId !== id);
      });

      // Update checked rows count
      this.$nextTick(() => {
        this.selectedCells = this.checkedRows;
      });
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }

      return obj;
    },
  },
};
</script>

<style lang="scss">
.listings {
  &__add-new {
    position: relative;
    margin: 0;
    z-index: 2;

    &--full {
      margin: 2rem 0 0;
      transition: background-color 0.15s, color 0.15s, border-color 0.15s;
      display: block;
      width: 100%;
      padding: 1rem;
      background: #edf2f5;
      border-radius: var(--input-radius);
      text-align: center;
      box-shadow: none;
      color: var(--faded-text-darker);
      border: 2px dashed var(--light-grey);

      &:hover {
        background: darken(#edf2f5, 7%);
        border: 2px dashed var(--faded-text);
        color: var(--text);
      }
    }
  }
}
</style>