<template>
  <span class="formbuilder__drag-thumb draggable">
    <SvgIcon
      name="drag"
      title="Drag arrows"
      class="formbuilder__drag-thumb__icon"
    />
  </span>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon.vue";

export default {
  name: "FormbuilderThumb",
  components: {
    SvgIcon,
  },
};
</script>

<style lang="scss">
.formbuilder__drag-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.9em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-grey);
  color: var(--text);
  border-radius: 0.3em;
  cursor: grab;
  transition: background-color 0.15s, color 0.15s;

  &:active {
    cursor: grabbing;
  }

  &:hover,
  &:active {
    background: var(--colour);
    color: white;
  }

  &__icon {
    width: 0.4em;
    height: 0.9em;
    fill: currentColor;
  }
}
</style>