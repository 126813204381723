<template>
  <div class="toast__window" aria-live="polite">
    <ToastPopup
      v-for="(toast, index) of toasts"
      :key="toast.id"
      :timeout="toast.timeout"
      :error="toast.error"
      :class="toast.colour"
      @remove-toast="removeToast(index)"
    >{{ toast.content }}</ToastPopup>
  </div>
</template>

<script>
import ToastPopup from "./Toast.vue";
import { mapState } from "vuex";

export default {
  name: "ToastWindow",
  components: {
    ToastPopup,
  },
  computed: mapState({
    toasts: (state) => state.toasts,
  }),
  methods: {
    removeToast(index) {
      this.$store.dispatch("removeToast", index);
    },
  },
};
</script>

<style lang="scss">
.toast__window {
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
  z-index: 999999999;
  width: calc(100% - 3em);
  pointer-events: none;
}
</style>
