/**
 *  Mixins for form elements
 *
 */
export default {
  props: {
    detailsProp: {
      type: Object,
      default: () => {}
    },
    inputKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    // Ensure maxlength=0 is ignored
    let maxlength = this.detailsProp?.maxlength;

    // Return details
    return {
      maxlength: maxlength || undefined,
      details: {...this.detailsProp},
      value: this.detailsProp.value
    };
  },
  computed: {
    labelClass() {
      return 'form__label--' + this.details.type;
    },
    inputClass() {
      return 'form__input--' + this.details.type;
    }
  },
  methods: {
    updateInput() {
      let {value} = this;

      this.$emit('update-form', {
        type: 'element',
        details: {...this.details, ...{value}}
      });
    }
  }
};
