<template>
  <div class="profile">
    <span
      class="profile__name"
      :class="{ 'loadblock loadblock--fixed': !loaded }"
      >{{ nickname }}</span
    >
    <button
      class="profile__icon"
      type="button"
      aria-controls="profile"
      :aria-expanded="popup"
      id="profile-button"
      aria-label="Button"
      @click.stop="toggle"
      ref="toggle"
    >
      <span class="profile__icon__clippath">
        <img class="profile__icon__image" :src="image" :alt="imageAlt" />
      </span>
      <div class="profile__icon__notifications" v-if="notifications"></div>
      <svg
        width="44"
        height="44"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44 44"
      >
        <clipPath id="profile-crop">
          <path
            d="m7.8,3.2c9.5,-9.4 36,6.3 36,19.5c0,13.3 -27.3,27 -36.3,18c-9,-9 -9.2,-28.1 0.3,-37.5z"
          />
        </clipPath>
      </svg>
    </button>

    <div
      class="profile__popup"
      role="region"
      aria-labelledby="profile-button"
      :hidden="!popup"
      @keydown.esc="hide"
      ref="popup"
    >
      <!-- <FocusLock> -->
      <button
        class="profile__popup__close"
        aria-label="Close popup"
        @click="hide"
        tabindex="3"
      >
        &times;
      </button>
      <div class="profile__popup__overview">
        <span class="profile__popup__clippath" tabindex="2">
          <img class="profile__popup__image" :src="image" :alt="imageAlt" />
        </span>
        <div class="profile__popup__name">{{ name }}</div>
        <span class="profile__popup__type">{{ role }}</span>
      </div>
      <ul
        v-if="menu && menu.length"
        class="profile__menu"
        role="menu"
        ref="menu"
        @keydown.up.prevent="selectPrevious"
        @keydown.down.prevent="selectNext"
      >
        <li
          role="menuitem"
          class="profile__menuitem"
          v-for="(item, i) of menu"
          :key="i"
        >
          <VueLink
            class="profile__menuitem__link"
            :href="item.href"
            :alt="item.text"
            :data-notifications="item.notifications"
            :tabindex="selectedIndex === i ? 0 : -1"
            @focus="setIndex(i)"
            >{{ item.text }}</VueLink
          >
        </li>
      </ul>
      <!-- </FocusLock> -->
    </div>
  </div>
</template>

<script>
import VueLink from "@/router/VueLink.vue";
import FocusLock from "vue-focus-lock";
import { mapState } from "vuex";
import clickout from "@/mixins/clickout.js";

export default {
  name: "DashboardProfile",
  mixins: [clickout],
  components: {
    VueLink,
    // FocusLock,
  },
  data: function () {
    return {
      popup: false,
      selectedIndex: 0,
    };
  },
  computed: mapState({
    loaded: (state) => state.firstLoad,
    name: (state) => state.profile?.name,
    role: (state) => state.profile?.role,
    image: () => require("@/assets/images/profile.jpg"), //(state) => state.profile?.image?.src,
    imageAlt: (state) => state.profile?.image?.alt,
    menu: (state) => state.profile?.menu,
    notifications() {
      let count = 0;

      // Loop through menu items
      this.menu?.forEach((menuitem) => {
        count += menuitem.notifications;
      });

      return count;
    },
    menuLinks() {
      return this.$el.querySelectorAll(".profile__menuitem__link");
    },
    menuMax() {
      return Math.max(this.menuLinks.length - 1, 0);
    },
    nickname() {
      let nickname = this.name?.split(" ")[0];

      return nickname ? `Hi, ${nickname}` : this.name;
    },
  }),
  methods: {
    toggle() {
      this.popup = !this.popup;

      // If visible, focus on first input
      if (this.popup) this.focusLink();
    },
    hide() {
      this.popup = false;

      // Focus back on toggle
      this.$refs.toggle.focus();
    },
    setIndex(index) {
      this.selectedIndex = index;
    },
    focusLink() {
      let selectedIndex = Math.min(this.selectedIndex, this.menuMax);

      // Focus on most recent selected menu item
      this.menuLinks.forEach(
        (link, index) => (link.tabIndex = selectedIndex === index ? "1" : "-1")
      );

      // Focus on relevant link
      this.$nextTick(() => {
        this.menuLinks[selectedIndex]?.focus();
      });
    },
    selectPrevious() {
      this.selectedIndex--;

      // Ensure within bounds
      if (this.selectedIndex < 0) this.selectedIndex = this.menuMax;

      // Focus on relevant item
      this.focusLink();
    },
    selectNext() {
      this.selectedIndex++;

      // Ensure within bounds
      if (this.selectedIndex > this.menuMax) this.selectedIndex = 0;

      // Focus on relevant item
      this.focusLink();
    },
    leave() {
      if (!this.popup) return;

      // Hide modal
      this.hide();
    },
    clickOutside(e) {
      if (!this.popup || this.$refs.popup.contains(e.target)) return;

      this.hide();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/sizes";

// Header
.profile {
  position: relative;
  height: $header-height;
  box-sizing: border-box;
  padding: $header-profile-padding 0;
  display: grid;
  grid-template-columns: auto $header-profile;
  grid-column-gap: 1.2rem;
  align-items: center;
  margin-left: auto;
  outline: none;

  &__icon {
    position: relative;
    padding: 0;
    margin: 0;
    width: $header-profile;
    height: $header-profile;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    overflow: hidden;

    &:hover {
      background: transparent;
      box-shadow: none;
    }

    &__notifications {
      position: absolute;
      top: 1px;
      right: 2px;
      width: 12px;
      height: 12px;
      background: var(--red);
      border-radius: 100%;

      &[data-notifications="0"] {
        display: none;
      }
    }

    &__clippath {
      display: block;
      width: $header-profile;
      height: $header-profile;
      overflow: hidden;
      clip-path: url(#profile-crop);

      @supports not (clip-path: url("#n")) {
        border-radius: 100%;
      }
    }

    &__image {
      display: block;
      width: $header-profile;
      height: $header-profile;
    }
  }

  &__name {
    display: block;
    font-size: 1rem;
    line-height: 1.2em;
    font-weight: 500;
    text-align: right;
  }

  &__menuitem,
  &__popup__clippath,
  &__popup__name,
  &__popup__type {
    opacity: 0;
    display: block;
    animation: 0.4s cubic-bezier(0.005, 0.895, 0.45, 0.995) forwards;
  }

  &__popup {
    position: absolute;
    top: #{$header-content + $header-padding + ($header-submenu-arrow * 0.7)};
    right: #{- $header-submenu-arrow / 2.5};
    width: $header-submenu-width;
    background: var(--text) url("~@/assets/images/motion-dark.svg") no-repeat
      70% 50%;
    background-size: cover;
    color: $header-submenu-color;
    box-shadow: 0 10px 90px -10px rgba(23, 36, 92, 0.2),
      0 5px 25px -5px rgba(23, 36, 92, 0.1), 0 0 3px rgba(23, 36, 92, 0.05);
    padding: 0;
    border-radius: 0.8em;
    animation: fadeUp 0.4s cubic-bezier(0.1, 0.8, 0.1, 0.8);
    box-shadow: inset 0 55px 40px -40px var(--text);

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: $header-submenu-arrow;
      height: $header-submenu-arrow;
      background-color: var(--text);
      top: #{-$header-submenu-arrow / 2};
      right: #{($header-profile - $header-submenu-arrow) / 1.2};
      transform: rotate(-45deg);
      border-top-right-radius: 0.25em;
      z-index: -1;
    }

    &__overview {
      padding: #{$header-profile / 1.2} #{$header-profile / 2};
      text-align: center;
    }

    &__clippath {
      margin: 0 auto 0.7rem;
      animation-name: zoomIn;
      animation-duration: 0.6s;
      animation-delay: 0.05s;
      width: $header-submenu-profile;
      height: $header-submenu-profile;
      border-radius: 100%;
    }

    &__image {
      width: $header-submenu-profile;
      height: $header-submenu-profile;
      border-radius: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }

    &__name {
      font-weight: 400;
      font-size: 1.5em;
      line-height: 1.2em;
      margin: 0 0 0.25rem;
      animation-name: fadeUp;
      animation-delay: 0.1s;
    }

    &__type {
      font-weight: 600;
      color: #7d8690;
      font-size: 0.66em;
      line-height: 1.2em;
      text-transform: uppercase;
      animation-name: fadeUp;
      animation-delay: 0.15s;
    }

    &__close {
      background: transparent;
      width: 1em;
      height: 1em;
      line-height: 1em;
      box-sizing: content-box;
      text-align: center;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 0.2em;
      right: 0.2em;
      padding: 0.1em;
      font-size: 1.5em;
      font-weight: light;
      opacity: 0;
      pointer-events: none;

      &:focus {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__menu {
    margin: 0;
    padding: #{$header-profile / 6} #{$header-profile / 1.5};
    background: rgba(126, 147, 175, 0.15);
    font-size: 0.9em;
  }

  &__menuitem {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1.2em;
    animation-name: fadeUp;
    animation-delay: 0.3s;
    border-top: 1px solid rgba(126, 147, 175, 0.3);

    &:first-child {
      border-top: 0;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: #{0.1 + ($i / 20)}s;
      }
    }

    &__link {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: #{$header-profile / 3.6} 0;
      font-weight: 300;
      color: inherit;

      &[data-notifications]:not([data-notifications="0"]):after {
        content: attr(data-notifications);
        display: inline;
        white-space: nowrap;
        line-height: 12px;
        padding: 5px;
        border-radius: 2em;
        min-width: 1.4em;
        text-align: center;
        font-size: 12px;
        margin: 0;
        font-weight: 600;
        background: var(--red);
        color: #fff;
      }
    }
  }
}
</style>
