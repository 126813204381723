<template>
  <div class="listings__manager">
    <SearchForm @run-search="search" />

    <div v-if="selectedCells.length" class="listings__manager__buttons">
      <button
        v-for="(button, index) of buttons"
        :key="button.text"
        :aria-label="button.text"
        class="listings__manager__button"
        :class="`listings__manager__button--${button.icon}`"
        :data-tooltip="button.text"
        type="button"
        @click="batchAction(button)"
        :style="{ animationDelay: `${index * 40}ms` }"
      >
        <SvgIcon
          :name="button.icon"
          :title="button.icon"
          class="listings__manager__button__icon"
        />
      </button>
    </div>
    <button
      @click.prevent="addRow"
      class="listings__add-new listings__add-new"
      aria-label="Add new row to table"
    >
      Add new
    </button>
  </div>
</template>

<script>
import SearchForm from "@/components/ui/SearchForm.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";

export default {
  name: "ListingsManager",
  components: {
    SearchForm,
    SvgIcon,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
    selectedCells: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    buttons() {
      return this.settings?.buttons.filter((button) => button.batch);
    },
  },
  methods: {
    search(e) {
      this.$emit("run-search", e);
    },
    addRow() {
      this.$emit("add-row");
    },
    batchAction(button) {
      this.$emit("batch-actions", { ...button });
    },
  },
};
</script>

<style lang="scss">
.listings__manager {
  --button-size: 48px;
  --icon-size: 28px;

  margin: 0 0 0.8em;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .search {
    justify-self: flex-start;
    margin-right: auto;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--button-size);
    min-width: var(--button-size);
    background-color: var(--input-bg);
    border-radius: var(--input-radius);
    color: var(--faded-text-darker);
    padding: 0;
    margin: 0 0.4em 0 0;
    box-shadow: none;
    animation: fadeRight 0.15s;
    animation-fill-mode: backwards;

    &:hover {
      background-color: var(--input-bg);
      color: var(--colour);
    }

    &__icon {
      fill: transparent;
      width: var(--icon-size);
      height: var(--icon-size);
      stroke: currentColor;
      stroke-width: 1.5px;
      stroke-linejoin: round;
      stroke-linecap: round;
    }

    &--delete:hover {
      color: var(--red);
    }
  }
}
</style>