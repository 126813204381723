<template>
  <div class="search">
    <form role="search" @submit="search">
      <fieldset>
        <legend id="sitesearch">Search CMS</legend>
        <div class="search__content">
          <input
            class="search__input"
            name="search"
            type="search"
            placeholder="Search"
            aria-label="Enter search terms here"
            aria-labelledby="sitesearch"
          />
          <button
            class="search__button"
            type="submit"
            aria-label="Search"
            title="Search"
          >
            <SvgIcon
              class="search__button__icon"
              title="Search icon"
              name="search"
            />
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon.vue";

export default {
  name: "SearchForm",
  components: {
    SvgIcon,
  },
  methods: {
    search(e) {
      e.preventDefault();

      // Get search query
      let query = e.target.search.value;

      // Throw event to be handled by dashboard
      this.$emit("run-search", {
        action: "search",
        query: query,
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/sizes";

$header-icon-size: 15px !default;
$header-icon-stroke: 5px !default;

// Search styling
.search {
  width: 100%;
  max-width: 18rem;

  &__content {
    position: relative;
    width: 100%;
    max-width: 18rem;
  }

  &__input {
    width: 100%;
    height: $header-content;
    padding-left: $header-content;
    margin: 0;
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: $header-content;
    height: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;

    &__icon {
      display: block;
      width: $header-icon-size;
      height: $header-icon-size;
      padding: #{($header-content - $header-icon-size) / 2};
      margin: 0;
      fill: transparent;
      stroke: var(--light-text);
      stroke-width: $header-icon-stroke;
      transition: stroke 0.15s;
    }

    &:hover {
      box-shadow: none;
      background: transparent;
    }

    &:hover &__icon {
      stroke: var(--text);
    }
  }
}
</style>
