/**
 *  Flatten input of elements
 * 
 *  @param {Array} data 
 */
export default function flattenForm(data = []){
  let form = [];

  // Ignore invalid types
  if (!Array.isArray(data)) return form;

  // Filter only relevant children, loop through all children
  data.filter(checkValidChild).forEach(el => {
    // Check if element is a grid
    if (el.type === 'grid') {
      // If a grid, recursively loop through groups' children
      form = form.concat(flattenForm(el.content));

      return;
    }

    // Add element to form
    form.push(el);
  });

  // Return
  return form;
}

/**
 *  Check child is of valid type
 * 
 *  @param {Object} data
 */
export function checkValidChild(data = {}){
  return Boolean(data && data.type && (data.content || data.details));
}
