/**
 *  @NOTE
 *  Because the Autobuilder is used to map values for inputs,
 *  related values in the Autobuilder will need to be mapped
 *  to the 'values' attribute for use in the final form.
 * 
 *  The below maps the attribute to the corresponding input
 *  types (as an array). So if an input of type 'toggle' is
 *  matched, the 'checked' value will replace 'value', and
 *  so forth...
 */
let maps = {
  checked: ['toggle', 'checkbox'],
  tags: ['multitags']
};

/**
 *  Transform attributes such as 'options' or 'checked' to 'value' field
 *
 *  @param {Object} details
 */
export default function mapAutobuilderValuesToProps(details = {}){
  let {type, value, checked} = details;

  // Update value to reflect 'checked' field
  if (maps.checked.includes(type)) {
    value = Boolean(checked);
  }

  // Default Multitag values to empty array
  if (maps.tags.includes(type)) {
    value = [];
  }

  // Return
  return {...details, ...{value}};
}
