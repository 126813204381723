import cellValue from './sort-values.js';

/**
 *  Ability to sort rows/columns
 * 
 */
export default {
  sortColumn(e) {
    // Invert direction if sortIndex already matches
    if (e === this.sortIndex) this.sortAsc = !this.sortAsc;

    // Update sortIndex
    this.sortIndex = e;

    // Get index and direction
    let index = this.sortIndex;
    let sortMethod = this.sortAsc ? [-1, 1, 0] : [1, -1, 0];

    // Perform sort
    this.tbody.sort((a, b) => {
      let _a = cellValue(index, a);
      let _b = cellValue(index, b);

      // If _a and _b don't exist, return nothing
      if (!_a && !_b) return 0;

      // If a is less than b, return a
      if (_a < _b) return sortMethod[0];

      // If a is greater than b, return b
      if (_a > _b) return sortMethod[1];

      // If a and b match, do nothing
      return sortMethod[2];
    });
  },
  sortAction(e) {
    if (e.eventPhase > 0) return;

    // Fetch ordered rowIds
    let rows = this.tbody.map(row => row.rowId);

    // Throw new event for reordering
    this.$emit('sort-action', rows);
  }
};
