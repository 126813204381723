/**
 *  Ability to toggle on/off rows of listing table
 * 
 */
export default {
  tick(row = null, checked = false) {
    if (!row) return;

    // Store filter change
    row.filters = {...row.filters, ...{checked}};

    // Emit event
    this.$emit('row-checked', this.checkedRows);
  },
  tog(row = null, active = false) {
    if (!row) return;

    // Store filter change
    row.filters = {...row.filters, ...{active}};

    // Emit event
    this.$emit('row-toggled', {row: row.rowId, active});
  },
  currentRow(index) {
    return this.tbody[this.findIndex(this.tbody, 'rowId', index)];
  },
  shiftKeyPress(e) {
    this.shift = e.shiftKey;
  },
  toggleRow(e) {
    this.tog(this.currentRow(e.rowId), e.active);
  },
  checkRow(e) {
    this.tick(this.currentRow(e.rowId), e.checked);

    // If shift key is held, and different nodes selected, multiselect
    if (this.shift && e.rowId !== this.previous.id) {
      this.selectBetween(e.rowId, this.previous.id, this.previous.checked);
    }

    // Save current selection to history
    this.previous = {
      id: e.rowId,
      checked: e.checked
    };

    // Uncheck master checkboxes (if checked)
    this.$refs.mastercheck.mastercheck = false;
    this.$refs.mastercheck.indeterminate = false;
    this.$refs.mastercheck.subcheck = -1;
  },
  selectBetween(a = -1, b = -1, checked = false) {
    let startIndex = this.findIndex(this.tbody, 'rowId', a);
    let endIndex = this.findIndex(this.tbody, 'rowId', b);

    // Ignore if either start/end doesn't exist for any reason
    if ([startIndex, endIndex].includes(-1)) return;

    // Sort to first and last
    let [start, end] = [startIndex, endIndex].sort((a, b) => a - b);

    // Loop through relevant nodes
    this.tbody.forEach((row, index) => {
      let matched = this.inRange(index, end, start);

      // If not matched within bounds, return
      if (!matched) return;

      // Check row
      this.tick(row, checked);
    });
  },
  toggleCheckboxes(e) {
    let {checked, value, negate} = e;

    // Loop through table and toggle relevant rows
    this.tbody.forEach(row => {
      let rowChecked = value ? false : checked;

      // Provide override if filter value is provided
      if (value) {
        // Filter matching rows
        let rowMatch = negate ? !row.filters[value] : row.filters[value];

        // Save toggle for matching rows accordingly
        if (rowMatch) rowChecked = checked;
      }

      // Toggle row as checked
      this.tick(row, rowChecked);
    });
  }
};
