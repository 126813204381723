<template>
  <form class="form" @submit.prevent="emitSubmit">
    <FormGroup
      :contentProp="content"
      :inputKeys="inputKeys"
      @update-form="(e) => syncData(e)"
    />

    <div class="form__submit">
      <button
        type="button"
        v-if="cancelable"
        aria-label="Cancel form submission"
        class="form__submit__button form__submit__button--cancel button button--cancel"
        @click.prevent="emitCancel"
      >
        Cancel
      </button>
      <button
        type="submit"
        aria-label="Confirm/save form submission"
        class="form__submit__button form__submit__button--success button button--confirm"
      >
        Save
      </button>
    </div>
  </form>
</template>

<script>
import FormGroup from "./FormGroup.vue";
import flattenForm from "./util/flatten.js";
import computedProps from "./util/props.js";

export default {
  name: "FormView",
  components: {
    FormGroup,
  },
  props: {
    contentProp: {
      type: Array,
      default: () => [],
    },
    cancelable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: computedProps(this.contentProp),
    };
  },
  computed: {
    inputKeys() {
      return ["name", "fabric", "foo"]; // this.contentFlat.map((el) => el.details.name);
    },
    contentFlat() {
      return flattenForm(this.content) || [];
    },
    formValues() {
      let formEntries = {};

      // Look through flattened array, compile data
      this.contentFlat.forEach((el) => {
        let { name, value } = el.details;

        // Save values
        formEntries[name] = value;
      });

      // Return
      return formEntries;
    },
  },
  methods: {
    syncData(e) {
      this.content = computedProps(e.content);
    },
    emitSubmit() {
      this.$emit("submit-form", this.formValues);
    },
    emitCancel() {
      this.$emit("cancel-form");
    },
  },
};
</script>

<style lang="scss">
.form {
  text-align: left;
  display: block;

  &__label {
    display: block;
    margin: 0 0 1.4em;
    line-height: 1.4em;
    color: var(--faded-text-darker);

    &--checkbox,
    &--toggle {
      margin: 0 0 0.7em;
    }

    &__maxlength {
      display: block;
      clear: both;
      margin: 0 0 -2.2em;
      padding: 0.5em 0.1em;
      font-size: 0.72em;
      line-height: 1.2em;
      text-align: right;
    }
  }

  &__input {
    margin: 0.4em 0 0;
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5em 0 0;

    &__button {
      margin: 0;

      &--save {
        margin-left: auto;
      }
    }
  }
}
</style>