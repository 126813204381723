// Type input
export default {
  name: 'type',
  type: 'dropdown',
  label: 'Input type',
  value: 'text',
  required: true,
  options: [
    {
      value: 'text',
      text: 'Text',
      selected: true
    },
    {
      value: 'textarea',
      text: 'Textarea'
    },
    {
      value: 'number',
      text: 'Number'
    },
    {
      value: 'dropdown',
      text: 'Dropdown'
    },
    {
      value: 'checkbox',
      text: 'Checkbox'
    },
    {
      value: 'toggle',
      text: 'Toggle'
    },
    {
      value: 'multitags',
      text: 'Multitags'
    }
  ]
};
