<template>
  <div class="nav" role="navigation" aria-label="Key CMS pages">
    <ul class="nav__menu" role="menu" v-if="loaded">
      <li
        class="nav__menu__section"
        role="none"
        v-for="(section, index) of menu"
        :key="index"
      >
        <h4
          class="nav__menu__title"
          :class="{ 'nav__menu--active': selectedSection === index }"
          :tabindex="focusedSection === index ? 0 : -1"
          :id="'menu_title_' + index"
          :aria-controls="'menu_list_' + index"
          :aria-expanded="selectedSection === index"
          role="button"
          @keydown.up.prevent="previousSection"
          @keydown.down.prevent="nextSection"
          @keydown.enter.prevent="accordion(index, true)"
          @keydown.space.prevent="accordion(index, true)"
          @click="accordion(index)"
          :ref="
            (el) => {
              headers[index] = el;
            }
          "
        >
          <SvgIcon
            class="nav__menu__icon"
            :title="section.icon.icon_alt"
            :name="section.icon.icon_svg"
          />
          {{ section.section_name }}
        </h4>

        <div
          class="nav__menu__accordion"
          role="region"
          :aria-labelledby="'menu_title_' + index"
          :id="'menu_list_' + index"
          :hidden="selectedSection !== index"
        >
          <ul
            class="nav__menu__submenu"
            :ref="
              (el) => {
                submenus[index] = el;
              }
            "
          >
            <li
              class="nav__menu__submenu__li"
              v-for="(page, p) in section.pages"
              :key="p"
            >
              <VueLink
                :href="page.page_url"
                :title="page.page_title"
                tabindex="-1"
                class="nav__menu__link"
                @keydown.escape.prevent="selectParent(index)"
                @keydown.left.prevent="selectParent(index)"
                @keydown.up.prevent="previousPage"
                @keydown.down.prevent="nextPage"
                role="menuitem"
                >{{ page.page_name }}</VueLink
              >
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <ul v-else class="nav__menu" role="presentation">
      <li class="nav__menu__section" role="none">
        <h4 class="nav__menu__title">
          <span class="nav__menu__icon nav__menu__icon--empty loadblock"></span>
          <span class="loadblock loadblock--widest loadblock--tall"></span>
        </h4>

        <div class="nav__menu__accordion">
          <ul class="nav__menu__submenu">
            <li class="nav__menu__submenu__li" v-for="index in 3" :key="index">
              <span class="nav__menu__link">
                <span class="loadblock loadblock--wide"></span>
              </span>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import VueLink from "@/router/VueLink.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import { ref, onBeforeUpdate } from "vue";
import { mapState } from "vuex";

export default {
  name: "DashboardMenu",
  components: {
    VueLink,
    SvgIcon,
  },
  computed: mapState({
    loaded: (state) => state.firstLoad,
    menu: (state) => state.menu,
  }),
  data: function () {
    return {
      focusedSection: 0,
      selectedSection: 0,
      selectedPage: 0,
    };
  },
  methods: {
    previousSection() {
      this.focusedSection--;

      // Focus
      this.sectionFocus();
    },
    nextSection() {
      this.focusedSection++;

      // Focus
      this.sectionFocus();
    },
    sectionFocus() {
      let max = this.headers.length - 1;

      // Loop bounds
      if (this.focusedSection < 0) {
        this.focusedSection = max;
      }
      if (this.focusedSection > max) {
        this.focusedSection = 0;
      }

      // Select relevant section
      this.headers[this.focusedSection]?.focus();
    },
    accordion(index, focus = false) {
      this.selectedSection = index;
      this.focusedSection = index;
      this.selectedPage = 0;

      // Select first link from submenu
      if (focus) {
        this.$nextTick(() => {
          this.fetchPages(index)[0]?.focus();
        });
      }
    },
    selectParent(index) {
      this.selectedSection = index;
      this.focusedSection = index;

      this.headers[index].focus();
    },
    previousPage() {
      this.selectedPage--;

      // Focus
      this.pageFocus();
    },
    nextPage() {
      this.selectedPage++;

      // Focus
      this.pageFocus();
    },
    fetchPages(index) {
      let menu = this.submenus[index];

      return menu?.querySelectorAll("a.nav__menu__link") || [];
    },
    pageFocus() {
      // Get pages from active menu
      let pages = this.fetchPages(this.selectedSection);

      // Get max pages
      let max = pages.length - 1;

      // Loop bounds
      if (this.selectedPage < 0) {
        this.selectedPage = max;
      }
      if (this.selectedPage > max) {
        this.selectedPage = 0;
      }

      // Focus if page exists
      pages[this.selectedPage]?.focus();
    },
  },
  setup() {
    const headers = ref([]);
    const submenus = ref([]);

    // make sure to reset the refs before each update
    onBeforeUpdate(() => {
      headers.value = [];
      submenus.value = [];
    });

    return {
      headers,
      submenus,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/sizes";

$nav-icon-size: 23px;
$nav-padding: 22px;
$nav-dropdown: 6px;

// Navbar
.nav {
  position: sticky;
  top: $header-height + $guttering;
  padding: $guttering 0;
  max-height: calc(100vh - #{$header-height + $guttering});
  overflow: auto;
  box-sizing: border-box;
  z-index: 12;

  // Hide scrollbars in Firefox
  scrollbar-width: none;

  // Hide scrollbars in Chrome/Safari/Edge
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;

    &__section {
      display: block;
      padding: 0;
      margin: 0.2rem 0 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &__title,
    &__link {
      display: grid;
      grid-template-columns: $nav-icon-size 1fr;
      grid-column-gap: $nav-padding;
      align-items: center;
      justify-content: flex-start;
      padding: 0.7rem 0;
      padding-left: $nav-padding;
      line-height: 1.4em;
      cursor: pointer;
      transition: color 0.15s;

      @media (min-width: 1500px) {
        padding-left: ($nav-padding + 16px);
      }

      &:hover {
        color: var(--colour);
      }
    }

    &__title {
      position: relative;
      padding-right: $guttering + $nav-dropdown;
      margin: 0;
      color: var(--text);
      font-weight: 600;
      font-size: 1rem;
      user-select: none;
      cursor: pointer;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - #{$nav-dropdown / 2});
        right: $nav-dropdown;
        width: $nav-dropdown;
        height: $nav-dropdown;
        border-top: 2px solid var(--colour);
        border-right: 2px solid var(--colour);
        transform: rotate(-45deg) scale(0.8);
      }
    }

    &__icon {
      display: block;
      width: $nav-icon-size;
      height: $nav-icon-size;
      transition: stroke 0.15s;
      fill: transparent;
      stroke: var(--colour);
      stroke-width: 1.6px;
      stroke-linecap: round;
      stroke-linejoin: round;
      margin: 0;

      // Mock/loading content
      &--empty {
        background-color: var(--faded-text);
        border-radius: 100%;
      }
    }

    &__submenu {
      padding: 0.2em 0 1em;
      margin: 0;
      display: block;

      &__li {
        margin: 0;
        padding: 0;
        animation: fadeUp 0.3s cubic-bezier(0.005, 0.895, 0.45, 0.995);
        animation-fill-mode: backwards;

        @for $i from 1 through 10 {
          &:nth-of-type(#{$i}) {
            animation-delay: #{1000 * ($i / 20)}ms;
          }
        }
      }
    }

    &__link {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      font-size: 0.92em;
      font-weight: 400;
      grid-column-gap: #{$nav-icon-size * 1.8};
      color: var(--light-text);

      &:before {
        content: "●";
        text-align: center;
        font-size: 0.7em;
        color: var(--faded-text);
      }

      &:focus,
      &:hover {
        color: var(--text);

        &:before {
          color: var(--colour);
        }
      }
    }

    &__accordion[hidden] {
      display: none;
    }

    &--active {
      box-shadow: inset 3px 0 0 var(--colour);

      // Rotate arrow down
      &::after {
        transform: rotate(135deg) scale(0.8);
      }

      &:hover {
        color: var(--text);
        cursor: default;
      }
    }
  }
}
</style>
