/**
 *  Allow saving
 * 
 */
export default {
  mounted() {
    document.addEventListener('keydown', this.checkSave);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.checkSave);
  },
  methods: {
    save() {
      return;
    },
    checkSave(e) {
      let ctrl = Boolean(e.ctrlKey || e.metaKey);
      let s = Boolean(e.keyCode === 83);

      // Check for key presses
      if (!(ctrl && s)) return;

      // Prevent default
      e.preventDefault();
      e.stopPropagation();

      // Perform save
      this.save();
    }
  }
};
