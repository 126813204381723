<template>
  <div class="form__label" :class="[labelClass, details.classname]">
    <InputToggle
      aria-label="Toggle"
      :label="details.label"
      :required="details.required"
      :checked="value"
      :class="inputClass"
      @change="updateInput"
      v-model="value"
    />
  </div>
</template>

<script>
import defaultData from "./__mixins.js";
import InputToggle from "@/components/ui/InputToggle.vue";

export default {
  name: "ToggleInput",
  mixins: [defaultData],
  components: {
    InputToggle,
  },
};
</script>

<style lang="scss"></style>