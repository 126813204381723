<template>
  <div class="form__label" :class="[labelClass, details.classname]">
    {{ details.label }}

    <fieldset class="autobuilder__conditions">
      <legend :id="uniqueId">All conditions</legend>

      <ul class="autobuilder__conditions__list" v-if="conditions.length">
        <li
          class="autobuilder__conditions__listitem"
          v-for="(condition, index) of conditions"
          :key="condition.conditionId"
        >
          <FormConditionsRow
            :conditionProp="condition"
            :inputKeys="inputKeys"
            :conditionId="condition.conditionId"
            @update-condition="(data) => updateCondition(data, index)"
            @remove-condition="removeCondition(index)"
          />
        </li>
      </ul>

      <button
        type="button"
        class="autobuilder__conditions__add-button"
        :aria-describedby="uniqueId"
        @click.prevent="addCondition"
      >
        Add condition
      </button>
    </fieldset>
  </div>
</template>

<script>
import FormConditionsRow from "./conditions-row.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import defaultData from "../__mixins.js";
import uniqueId from "@/mixins/unique-id.js";

export default {
  name: "FormConditions",
  components: {
    FormConditionsRow,
    SvgIcon,
  },
  mixins: [defaultData, uniqueId],
  data() {
    let id = this.uniqueId;

    return {
      conditions: [...this.value],
      fieldset: id + `-fieldset-`,
    };
  },
  methods: {
    addCondition() {
      if (!Array.isArray(this.conditions)) this.conditions = [];

      // Generate random ID, for loop
      let conditionId = Math.random().toString(36).substr(2, 11);

      // Add new row, default dropdown to first available option
      this.conditions.push({
        conditionId,
        input: "",
        values: [],
      });
    },
    updateCondition(data, index) {
      this.conditions.splice(index, 1, data);
    },
    removeCondition(index = -1) {
      this.conditions.splice(index, 1);
    },
  },
  watch: {
    conditions: {
      handler() {
        // Strip keys from conditions
        this.value = this.conditions.map((condition) => {
          let { input, values } = condition;

          return { input, values };
        });

        // Update parent
        this.updateInput();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.autobuilder__conditions {
  &__list {
    list-style: none;
    margin: 1em 0;
    padding: 0;
  }

  &__listitem {
    padding: 0;
    margin: 0 0 0.7em;
  }

  &__add-button {
    display: block;
    width: 100%;
    text-align: center;
    margin: 1.5em 0 0;
  }
}
</style>