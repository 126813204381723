<template>
  <div class="formbuilder__item" tabindex="0">
    <FormbuilderThumb class="formbuilder__item__drag-item" />

    <button
      class="formbuilder__item__delete"
      aria-label="Delete this item"
      title="Delete item"
      @click="deleteItem"
    >
      <SvgIcon
        name="cross"
        title="Delete icon"
        class="formbuilder__item__delete__icon"
      />
    </button>

    <button class="formbuilder__item__name" @click.prevent="updateElement">
      <span class="formbuilder__item__name__type">{{ overview.type }}</span>
      {{ overview.name }}
    </button>
  </div>
</template>

<script>
import FormbuilderThumb from "./FormbuilderThumb.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import encodeAutobuilder from "@/api/autobuilder/";
import mapAutobuilderValuesToProps from "./mixins/map-values.js";

export default {
  name: "FormbuilderItem",
  components: {
    FormbuilderThumb,
    SvgIcon,
  },
  props: {
    detailsProp: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    details() {
      return { ...this.detailsProp };
    },
    overview() {
      let { type, name } = this.details;

      return {
        name,
        type,
      };
    },
  },
  methods: {
    deleteItem() {
      this.$emit(
        "delete-item",
        "Are you sure you want to delete this row, and any associated data?"
      );
    },
    updateElement() {
      new Promise((resolve) => {
        this.$store.dispatch("modal", {
          type: "form",
          form: encodeAutobuilder(this.details),
          resolve: resolve,
          buttons: {
            confirm: "Update item",
            deny: "Cancel",
          },
        });
      }).then((form) => {
        if (!form) return;

        // Emit update event
        this.$emit("content-updated", {
          details: mapAutobuilderValuesToProps(form),
        });
      });
    },
  },
};
</script>

<style lang="scss">
.formbuilder__item {
  position: relative;
  user-select: none;
  border-radius: 0.5em;
  overflow: hidden;
  background: var(--light-grey);
  color: var(--text);

  &__name,
  &__delete {
    transition: color 0.15s, background-color 0.15s;

    &:hover {
      background: transparent;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: transparent;
    color: currentColor;
    border-radius: 0.5em;
    padding: 0.7em 1.1em;
    min-height: 3.6em;
    height: auto;
    padding-left: 2.3em;
    margin: 0;
    text-align: left;

    &__type {
      display: block;
      color: white;
      background: var(--faded-text-darker);
      padding: 0.4em 1.2em;
      margin: 0 0.7em 0 0;
      border-radius: 2em;
      text-transform: uppercase;
      font-size: 0.7em;
    }

    &:hover {
      background: var(--colour);
    }
  }

  &__delete {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    color: currentColor;
    min-width: 1.5em;
    height: auto;
    text-align: center;
    padding: 0.7em;
    font-size: 1.35em;
    border-radius: 0.3em;
    margin: 0;

    &__icon {
      display: block;
      margin: auto;
      width: 0.6em;
      height: 0.6em;
      stroke: currentColor;
      stroke-width: 5px;
      fill: transparent;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
  }

  &__drag-item {
    background: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 1.5em;
    color: currentColor;
  }

  &__delete:hover ~ &__name {
    background: var(--input-error);
  }

  &:hover {
    color: white;
  }

  &__drag-item:hover ~ &__name,
  &__drag-item:hover ~ &__delete {
    background: transparent;
    color: var(--text);
  }

  &:focus-within &__delete,
  &:hover &__delete {
    visibility: visible;
  }
}
</style>