<template>
  <section class="formbuilder">
    <h1 v-if="content.title" class="formbuilder__title">{{ content.title }}</h1>
    <p v-if="content.description" class="formbuilder__description">
      {{ content.description }}
    </p>

    <FormbuilderGroup
      :contentProp="list"
      :nested="false"
      @content-updated="contentUpdated"
    />

    <div class="formbuilder__add">
      <button
        class="formbuilder__add__button formbuilder__add__button--group button button--cancel"
        aria-label="Add new group wrapper to FormBuilder"
        @click.prevent="addGroup"
        title="Add group"
      >
        New group
      </button>
      <button
        class="formbuilder__add__button formbuilder__add__button--item button button--confirm"
        aria-label="Add new item to FormBuilder"
        @click.prevent="addItem"
        title="Add form element"
      >
        New form element
      </button>
    </div>
  </section>
</template>

<script>
import FormbuilderGroup from "./FormbuilderGroup.vue";
import postData from "@/mixins/post.js";
import uniqueId from "@/mixins/unique-id.js";
import encodeAutobuilder from "@/api/autobuilder/";
import mapAutobuilderValuesToProps from "./mixins/map-values.js";

export default {
  name: "FormBuilder",
  mixins: [postData, uniqueId],
  components: {
    FormbuilderGroup,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let list = this.content?.builder || [];

    return {
      list: [...list],
    };
  },
  methods: {
    addGroup() {
      this.list.push({
        type: "grid",
        name: this.generateId(),
        details: {},
        content: [],
      });

      // Prepare for server post
      this.prepareForServer();
    },
    addItem() {
      new Promise((resolve) => {
        this.$store.dispatch("modal", {
          type: "form",
          form: encodeAutobuilder(),
          resolve: resolve,
          buttons: {
            confirm: "Add item",
            deny: "Cancel",
          },
        });
      }).then((form) => {
        if (!form) return;

        // Add new item to server
        this.list.push({
          type: "element",
          name: this.generateId(),
          details: mapAutobuilderValuesToProps(form),
        });

        // Prepare for server post
        this.prepareForServer();
      });
    },
    contentUpdated(e) {
      let { content, updateserver } = e;

      // Save updated content
      this.list = content;

      // Prepare for server post
      if (updateserver) this.prepareForServer();
    },
    prepareForServer() {
      console.log("Post to server", JSON.parse(JSON.stringify(this.list)));
    },
    // postToServer(url, data);
  },
};
</script>

<style lang="scss">
.formbuilder__add {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1.5em 0 0;

  &__button {
    margin: 0 0 0 0.8em;
  }
}
</style>