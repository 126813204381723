<template>
  <div
    class="multicheck"
    :class="{ 'multicheck--active': expanded }"
    @mouseleave="hideModal"
  >
    <div class="multicheck__root">
      <InputCheckbox
        @input="toggleMaster"
        :indeterminate="indeterminate"
        :checked="mastercheck"
        aria-label="Select all rows"
      />

      <button
        v-if="checkboxes.length"
        class="multicheck__toggle"
        @click.prevent="showModal"
        @mouseenter="showModal"
        :aria-controls="uniqueId"
        :aria-expanded="expanded ? 'true' : 'false'"
        aria-label="Show multicheck modal"
        ref="toggle"
      >
        <svg
          width="26"
          height="38"
          viewBox="0 0 26 38"
          xmlns="http://www.w3.org/2000/svg"
          class="multicheck__toggle__arrow"
        >
          <title>Multichecker modal arrow</title>
          <path
            d="M12.2929 22.7929L7.70711 18.2071C7.07714 17.5771 7.52331 16.5 8.41421 16.5H17.5858C18.4767 16.5 18.9229 17.5771 18.2929 18.2071L13.7071 22.7929C13.3166 23.1834 12.6834 23.1834 12.2929 22.7929Z"
          />
        </svg>
      </button>
    </div>

    <ul
      v-if="checkboxes.length"
      class="multicheck__modal"
      :hidden="!expanded"
      :id="uniqueId"
      tabindex="-1"
      @keydown.escape.stop="keyboardHideModal"
    >
      <!-- <FocusLock> -->
      <li
        class="multicheck__modal__option"
        v-for="(checkbox, index) of checkboxes"
        :key="checkbox.text"
      >
        <InputCheckbox
          @input="(checked) => toggleSub(checkbox, checked, index)"
          :checked="subcheck === index"
          :label="checkbox.text"
        />
      </li>
      <!-- </FocusLock> -->
    </ul>
  </div>
</template>

<script>
import InputCheckbox from "@/components/ui/InputCheckbox.vue";
import clickout from "@/mixins/clickout.js";
import uniqueId from "@/mixins/unique-id.js";
import FocusLock from "vue-focus-lock";

export default {
  name: "MultiCheck",
  mixins: [clickout, uniqueId],
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    InputCheckbox,
    // FocusLock
  },
  data() {
    return {
      checkboxes: this.options || [],
      indeterminate: false,
      mastercheck: false,
      subcheck: -1,
      expanded: false,
    };
  },
  methods: {
    toggleModal() {
      if (this.expanded) {
        this.hideModal();

        return;
      }

      this.showModal();
    },
    showModal() {
      this.expanded = true;
    },
    hideModal() {
      this.expanded = false;
    },
    keyboardHideModal() {
      this.hideModal();

      // Focus back on toggle
      this.$refs.toggle.focus();
    },
    clickOutside(e) {
      if (!this.expanded || this.$el.contains(e.target)) return;

      this.hideModal();
    },
    // checked, index, value = null
    toggleSub(checkbox, checked = null, index) {
      this.indeterminate = checked ?? false;
      this.mastercheck = false;
      this.subcheck = index;

      // Get value, and whether checkbox is negated
      let { value, negate } = checkbox;

      // Throw action
      this.actionCheck(checked, value, negate);
    },
    toggleMaster(checked) {
      this.indeterminate = false;
      this.mastercheck = checked;
      this.subcheck = -1;

      this.actionCheck(checked, null);
    },
    actionCheck(checked, value = null, negate = false) {
      this.$emit("batch-checked", {
        ...{ checked },
        ...{ value },
        ...{ negate },
      });
    },
  },
};
</script>

<style lang="scss">
.multicheck {
  position: relative;
  z-index: 8;
  padding: 0.8em;
  margin: -0.8em;
  width: 3em;

  &--active {
    background: white;
    border-top-left-radius: 0.6em;
    border-top-right-radius: 0.6em;
  }

  &__root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__toggle {
    position: absolute;
    top: 0;
    right: 0.2em;
    bottom: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    width: 1em;
    border-radius: 0;
    color: var(--faded-text);

    &:hover {
      background: transparent;
      color: var(--colour);
    }

    &__arrow {
      display: block;
      margin: 0;
      width: 1em;
      height: 100%;
      fill: currentColor;
    }
  }

  &__modal {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 14em;
    background: white;
    color: var(--text);
    border-radius: 0.6em;
    border-top-left-radius: 0;
    padding: 0.4em 0.8em;
    box-sizing: border-box;
    text-align: left;
    box-shadow: 0 4px 4px -2px rgba(#000, 0.1), 0 10px 25px -5px rgba(#000, 0.1);
    z-index: 4;
    animation: fadeIn 0.4s cubic-bezier(0.005, 0.895, 0.45, 0.995);
    list-style: none;

    &:focus {
      outline: none;
    }
  }

  &__modal__option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.45em 0;
    text-transform: none;
    font-size: 0.89rem;
    color: var(--light-text);
    font-weight: 500;
  }

  .checkbox {
    font-size: 1rem;
    letter-spacing: 0;
    padding-right: 1em;
  }
}
</style>