/**
 *  Override default link behavior
 * 
 */
export default {
  methods: {
    go(e, url, link = null) {
      if (!url || url === '#') return;

      // Check if link is external
      if (link && location.hostname !== link.hostname) return true;

      // Prevent default behaviour
      e.preventDefault();

      // Save data
      let linkData = {
        url: url
      };

      // Update pushstate
      window.history.pushState(linkData, document.title, url);

      // Update state
      this.$store.dispatch('fetch', linkData);
    }
  }
};
