<template>
  <label
    role="checkbox"
    class="togglebox"
    :class="{
      'togglebox--active': isChecked,
      'togglebox--indeterminate': isIndeterminate,
      'togglebox--rtl': rtl,
    }"
    :aria-checked="isChecked ? 'true' : 'false'"
    tabindex="0"
    @keyup.enter="keyCheck"
    @keyup.space="keyCheck"
    @click="mouseClick"
  >
    <svg
      class="togglebox__icon"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="22"
      viewBox="0 0 36 22"
      :disabled="isDisabled"
    >
      <rect
        class="togglebox__handle"
        x="2"
        y="2"
        width="31"
        height="17"
        rx="9"
        ry="9"
        stroke-width="2"
        fill="transparent"
      />
      <g class="togglebox__thumb">
        <circle
          class="togglebox__thumb__inner"
          cx="10.5"
          cy="10.5"
          r="5.5"
          stroke-width="0"
        />
      </g>
    </svg>
    {{ label }}
  </label>
</template>

<script>
export default {
  name: "InputToggle",
  props: {
    label: String,
    checked: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isChecked: this.checked,
      isDisabled: this.disabled ? "disabled" : null,
      isIndeterminate: this.indeterminate,
    };
  },
  methods: {
    keyCheck(e) {
      if (this.disabled) {
        e.stopImmediatePropagation();

        return;
      }

      // Prevent (e.g.) spacebar 'skip'
      e.preventDefault();

      // Toggle checkbox
      this.toggleCheckbox();
    },
    mouseClick(e) {
      if (this.disabled) {
        e.stopImmediatePropagation();

        return;
      }

      this.toggleCheckbox();
    },
    toggleCheckbox() {
      this.isChecked = Boolean(!this.isChecked);

      // Disabled indeterminate on any toggle
      this.isIndeterminate = false;

      // Emit event
      this.$emit("update:modelValue", this.isChecked);
      this.$emit("input", this.isChecked);
      this.$emit("change", this.isChecked);
    },
  },
  watch: {
    checked() {
      this.isChecked = this.checked;

      if (this.isChecked === true) this.isIndeterminate = false;
    },
    indeterminate() {
      this.isIndeterminate = this.indeterminate;

      if (this.isIndeterminate === true) this.isChecked = false;
    },
  },
};
</script>

<style lang="scss">
$size: 1.35em;

.togglebox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  line-height: 1.35em;

  &__icon {
    display: block;
    width: 2.45em;
    height: 1.5em;
    margin: 0 0.6em 0 0;
    padding: 0;

    &[disabled] {
      pointer-events: none;
      filter: grayscale(100%) brightness(170%);
    }
  }

  &__handle {
    fill: rgba(#2b4599, 0.12);
    transition: fill 0.15s;
  }

  &__thumb {
    transform-origin: 24px 10px;

    &__inner {
      fill: #e42929;
      transition: transform 0.15s;
    }
  }

  &:active &__thumb {
    transform: scale(0.94);
  }

  &:hover &__icon:not([disabled="true"]) &__handle {
    fill: rgba(#2b4599, 0.2);
  }

  &--rtl {
    justify-content: space-between;
  }

  &--rtl &__icon {
    order: 2;
    margin: 0 0 0 0.6em;
  }

  &--active &__thumb__inner {
    fill: #76aa5f;
    transform: translate(14px, 0);
  }

  &--indeterminate &__thumb__inner {
    fill: #9da2aa;
    transform: translate(7px, 0);
  }
}
</style>
