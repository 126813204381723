/**
 *  Closure function for defaulting settings
 * 
 */
let override = defaults => {
  return (userData = null) => {
    // Update value accordingly
    defaults.value = userData;

    // Create clone of object
    let clonedData = {...defaults};

    // Delete private _value (used by getters/setters)
    delete clonedData._value;

    // Return
    return clonedData;
  };
};

/**
 *  Default classname
 * 
 */
let classnameDefaults = override({
  is: 'input',
  type: 'text',
  label: 'Custom class name',
  model: 'classname',
  _value: '',
  get value() {
    return this._value;
  },
  set value(val) {
    if (!val || typeof val !== 'string') {
      this._value = '';

      return;
    }

    this._value = val.replace(/[^\w-0-9]+/g, '');
  }
});

/**
 *  Default gridsize
 * 
 */
let gridsizeDefaults = override({
  is: 'range-button',
  label: 'Column count',
  range: [1, 2, 3, 4],
  model: 'gridsize',
  _value: 1,
  get value() {
    return this._value;
  },
  set value(val) {
    if (!Number.isInteger(val)) {
      this._value = 1;

      return;
    }

    this._value = Math.min(4, Math.max(1, Number(val)));
  }
});

// Return
export {gridsizeDefaults, classnameDefaults};
