// Type input
export default {
  name: 'wysiwyg',
  label: 'Include full editor?',
  type: 'checkbox',
  checked: true,
  conditions: [
    {
      input: 'type',
      values: ['textarea']
    }
  ]
};
