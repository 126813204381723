<template>
  <div class="banner">Image component</div>
</template>

<script>
export default {
  name: "DashboardImage",
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.banner {
  color: green;
}
</style>