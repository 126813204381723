/**
 *  Monitor clicking outside an element
 * 
 */
export default {
  methods: {
    clickOutside() {
      return;
    }
  },
  mounted() {
    window.addEventListener('click', this.clickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.clickOutside);
  }
};
