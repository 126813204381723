import flattenForm from './flatten.js';
import checkIsHidden from './hidden.js';

/**
 *  Add 'hidden' and 'type' computed values to FormBuilder
 * 
 *  @param {Array} data 
 */
export default function computedProps(data = []){
  let dataFlat = flattenForm(data);

  /**
   *  Recursive loop to update data types
   * 
   *  @param {*} elements 
   */
  function saveFormTypeAndHidden(elements = []){
    let form = [];

    // Loop through elements and transform as necessary
    elements.forEach(element => {
      // Add group to form
      if (element.type === 'grid') {
        let content = saveFormTypeAndHidden(element.content);

        // If no visible children, hide group
        let _grouphide = noVisibleChildElements(content);

        // Apply hidden attribute to group
        let details = {...element.details, _grouphide};

        // Add group and content to form
        form.push({...element, ...{_is: 'form-group', details, content}});

        return;
      }

      // Add element to form
      form.push({
        ...element,
        ...{
          _is: `form-${element.details.type}`,
          _hidden: checkIsHidden(element.details, dataFlat)
        }
      });
    });

    return form;
  }

  return saveFormTypeAndHidden(data);
}

/**
 *  Check if group contains visible children
 * 
 *  @param {Array} content
 */
export function noVisibleChildElements(content = []){
  // Check child count
  let children = flattenForm(content);

  // Check how many children are hidden
  let hiddenChildren = children.filter(el => el._hidden || el.details?.hidden);

  // Check if any are visible
  return Boolean(children && hiddenChildren.length === children.length);
}
