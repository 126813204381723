<template>
  <div role="table" class="listings">
    <div v-if="tbody.length" role="thead" class="listings__thead">
      <ul
        role="row"
        class="listings__row"
        :class="{
          'listings__row--padleft': !leftCol,
          'listings__row--padright': !rightCol,
        }"
      >
        <li
          class="listings__cell listings__cell--overflow"
          :class="{
            'listings__cell--checkbox': settings.multiselect,
            'listings__cell--hasthumb': settings.draggable,
          }"
          role="column"
          v-if="leftCol"
        >
          <MultiCheck
            v-if="settings.multiselect"
            :options="settings.multiselect"
            @batch-checked="toggleCheckboxes"
            ref="mastercheck"
          />
        </li>
        <li
          v-for="(cell, index) of thead"
          :key="index"
          class="listings__cell"
          :class="{ 'listings__cell--sortable': cell.sortable }"
          role="column"
        >
          <button
            v-if="cell.sortable && !settings.draggable"
            class="listings__cell__sort-button"
            tabindex="0"
            @click="sortColumn(index)"
          >
            {{ cell.name }}
          </button>
          <span v-else class="listings__cell__header">{{ cell.name }}</span>
        </li>
        <li v-if="rightCol" class="listings__cell" role="column"></li>
      </ul>
    </div>

    <component
      :is="draggable ? 'VueDraggableNext' : 'div'"
      :list="draggable ? tbody : null"
      :ghost-class="draggable ? 'listings__row--ghost' : null"
      :handle="draggable ? '.listings__cell__thumb' : null"
      class="listings__tbody"
      role="tbody"
      v-on="draggable ? { end: sortAction } : null"
    >
      <ListingsTableRow
        v-for="row of tbody"
        :key="row.rowId"
        :cells="row.td"
        :filters="row.filters"
        :actions="row.actions"
        :row-id="row.rowId"
        :settings="settings"
        :left-col="leftCol"
        :right-col="rightCol"
        @row-toggled="toggleRow"
        @row-checked="checkRow"
        @row-action="actionRow"
      />
    </component>
  </div>
</template>

<script>
import ListingsTableRow from "./table-row.vue";
import arraySearch from "@/mixins/arrays.js";
import MultiCheck from "@/components/ui/MultiCheck.vue";
import { VueDraggableNext } from "vue-draggable-next";

// Extra utility
import listingsToggle from "./util/toggle.js";
import listingsSort from "./util/sort.js";
import listingsAction from "./util/actions.js";

export default {
  name: "ListingsTable",
  props: {
    settingsProp: {
      type: Object,
      default: () => {},
    },
    theadProp: {
      type: Object,
      default: () => {},
    },
    tbodyProp: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ListingsTableRow,
    MultiCheck,
    VueDraggableNext,
  },
  mixins: [arraySearch],
  data() {
    let settings = this.settingsProp;
    let thead = this.theadProp;

    // Check for key info from settings
    let { draggable, multiselect, toggle, buttons } = settings;

    // Check whether left/right column is populated
    let leftCol = draggable || multiselect;
    let rightCol = toggle || (buttons && buttons.length);

    // Return data
    return {
      settings,
      thead,
      draggable,
      leftCol,
      rightCol,
      previous: null,
      shift: false,
      sortIndex: -1,
      sortAsc: true,
    };
  },
  methods: {
    ...listingsToggle,
    ...listingsSort,
    ...listingsAction,
  },
  computed: {
    checkedRows() {
      return this.tbody.filter((row) => row.filters.checked);
    },
    tbody() {
      let tbody = this.tbodyProp || [];
      let settings = this.settings;

      // Add relevant default filters to tbody
      tbody.forEach((row) => {
        row.filters = {
          ...{
            ...{ checked: false, active: true },
            ...settings.defaultFilters,
          },
          ...row.filters,
        };
      });

      return tbody;
    },
  },
  mounted() {
    window.addEventListener("keydown", this.shiftKeyPress);
    window.addEventListener("keyup", this.shiftKeyPress);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.shiftKeyPress);
    window.removeEventListener("keyup", this.shiftKeyPress);
  },
};
</script>

<style lang="scss">
.listings {
  display: table;
  width: 100%;
  margin: 0;
  color: var(--text);

  &.loading {
    position: relative;
    padding-bottom: 16rem;
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      background: url("/img/loading.svg") no-repeat center;
      position: absolute;
      top: 4rem;
      left: 0;
      width: 100%;
      height: 16rem;
    }
  }

  &.loading &__tbody {
    display: none;
  }

  // Main table sections
  &__thead,
  &__tbody {
    display: contents;
    list-style: none;
    padding: 0;
  }

  // Main cell styling
  &__row {
    display: table-row;

    &__content {
      display: contents;
    }
  }

  &__cell {
    display: table-cell;
    overflow: hidden;

    &--overflow {
      overflow: visible;
    }

    &__header {
      display: block;
    }

    &__sort-button,
    &__header {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    &__sort-button {
      cursor: pointer;
      background: transparent url("/img/sortable-default.svg") no-repeat right;
      color: currentColor;
      padding: 0.5rem 1rem;
      padding-left: 0;
      text-decoration: underline;
      text-decoration-style: dotted;
      text-underline-offset: 0.3em;
      transition: color 0.15s;
      font: inherit;
      line-height: inherit;
      text-transform: inherit;
      border-radius: 0;
      box-shadow: none;

      &:hover {
        background-color: transparent;
        color: var(--colour);
      }
    }

    &--sortactive {
      background-image: url("/img/sortable-active.svg");
      color: var(--colour);

      [data-order="ASC"] & {
        background-image: url("/img/sortable-asc.svg");
      }

      [data-order="DSC"] & {
        background-image: url("/img/sortable-dsc.svg");
      }
    }
  }

  // Head styling
  &__thead &__row {
    background: var(--dark-bg);
    color: var(--faded-text);
  }

  &__thead &__cell {
    font-weight: 600;
    font-size: 0.7em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 1rem 0.5rem;
    z-index: 3;

    &--checkbox {
      padding-left: 0.8rem;
    }

    &--sortable {
      padding: 0.5rem;
      overflow: visible;
    }
  }

  // Match tbody thumb offset in thead
  &__thead &__cell--checkbox#{&}__cell--hasthumb {
    padding-left: 1.2rem;
  }

  // Row padding
  &__row--padleft > &__cell:first-child {
    padding-left: 1.2rem;
  }

  &__row--padright > &__cell:last-child {
    padding-right: 1.2rem;
  }
}
</style>