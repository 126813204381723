<template>
  <div class="markdown" v-html="markdown" />
</template>

<script>
import showdown from "showdown";
import linkOverride from "@/mixins/link.js";

export default {
  name: "DashboardMarkdown",
  mixins: [linkOverride],
  props: {
    content: {
      type: String,
      default: "# Blank page",
    },
  },
  computed: {
    markdown() {
      let parser = new showdown.Converter();

      // Set options for ShowDown
      parser.setFlavor("github");

      // Add HTML to element
      return parser.makeHtml(this.content);
    },
  },
};
</script>

<style lang="scss">
.markdown {
  // headers
  h1 {
    font-size: 1.8em;
    border-bottom: 1px solid var(--light-grey);
    padding-bottom: 0.4em;
  }

  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.2em;
  }

  h5,
  h6 {
    font-size: 0.85em;
  }

  h6 {
    color: var(--light-text);
  }

  // line dividers
  hr {
    margin: 2.5rem 0;
    border: 0;
    border-top: 1px solid var(--light-grey);
  }

  // images
  img {
    max-width: 100%;
  }

  // blockquotes
  blockquote {
    border-left: 5px solid var(--light-grey);
    margin-left: 0;
    padding-left: 1em;
    color: var(--light-text);
    font-style: italic;
  }

  // lists
  ul,
  ol {
    p {
      margin: 0;

      + p {
        margin-top: 1em;
      }
    }
  }

  li {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    line-height: 1.5em;
  }

  // code
  pre,
  code {
    border: 1px solid;
    border-radius: 0.2em;
  }

  code {
    padding: 0.12em 0.3em;
    font-size: 0.85em;
    background-color: #f9f2f4;
    border-color: #ecd9de;
    color: #c7254e;
  }

  // code (blocks)
  pre {
    word-break: break-all;
    word-wrap: break-word;
    padding: 0.5em 1em;
    line-height: 1.4em;
    background-color: var(--dark-bg);
    border-color: var(--light-grey);
    color: var(--text);

    code {
      border: 0;
      background: transparent;
      color: inherit;
    }
  }

  // tables
  table {
    width: 100%;
    border-collapse: collapse;
  }

  td,
  th {
    border: 1px solid var(--light-grey);
  }

  th {
    background: #edf1f7;
    color: #8694a3;
    font-weight: 600;
    font-size: 0.72rem;
    line-height: 1.4em;
    text-transform: uppercase;
    padding: 0.8rem;
    letter-spacing: 0.1em;
    vertical-align: middle;
    white-space: nowrap;
  }

  td {
    font-weight: 300;
    font-size: 1rem;
    padding: 0.6rem 0.8rem;
    vertical-align: top;
  }

  tr:nth-child(even) {
    background: #f6f8fa;
  }

  // inputs/checkboxes
  input[type="checkbox"] {
    appearance: checkbox;
    display: inline-block;
    margin: 0 0.8em 0.25em -1.6em;
    vertical-align: middle;
    width: auto;
    height: auto;
  }
}
</style>