// Type input
export default {
  name: 'name',
  label: 'Column name (alphneumerical)',
  type: 'text',
  value: '',
  placeholder: 'Database column name',
  pattern: '[A-Za-z0-9]*',
  required: true,
  unique: true
};
