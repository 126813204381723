// Type input
export default {
  name: 'options',
  label: 'Add options to dropdown',
  type: 'autobuilder-dropdown',
  value: [],
  conditions: [
    {
      input: 'type',
      values: ['dropdown']
    }
  ]
};
