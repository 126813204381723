// Type input
export default {
  name: 'step',
  label: 'Precision/step',
  type: 'number',
  value: '',
  step: 'any',
  placeholder: '0.01',
  conditions: [
    {
      input: 'type',
      values: ['number']
    }
  ],
  allconditions: false
};
