// Type input
export default {
  name: 'checked',
  label: 'Checked by default?',
  type: 'checkbox',
  checked: false,
  conditions: [
    {
      input: 'type',
      values: ['checkbox', 'toggle', 'radio']
    }
  ],
  allconditions: false
};
