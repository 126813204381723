<template>
  <label class="form__label" :class="[labelClass, details.classname]">
    {{ details.label }}
    <input
      type="text"
      :placeholder="details.placeholder"
      :required="details.required"
      :pattern="details.pattern"
      :maxlength="maxlength"
      class="form__input"
      :class="inputClass"
      @change="updateInput"
      v-model="value"
    />

    <span v-if="maxlength" class="form__label__maxlength">
      {{ value.length }} of {{ maxlength }}
    </span>
  </label>
</template>

<script>
import defaultData from "./__mixins.js";

export default {
  name: "TextInput",
  mixins: [defaultData],
};
</script>

<style lang="scss"></style>