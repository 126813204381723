<template>
  <div class="settings__option__range-button">
    <label
      class="settings__option__label settings__option__label--range-button"
      :id="uniqueId"
      v-if="label"
      >{{ label }}</label
    >

    <div
      class="settings__option__range-button__grid"
      :style="{ gridTemplateColumns }"
    >
      <button
        v-for="number of range"
        :key="number"
        class="settings__option__range-button__button"
        :class="{
          'settings__option__range-button__button--active': number === value,
        }"
        @click="$emit('option-updated', { value: number, model: 'gridsize' })"
      >
        {{ number }}
      </button>
    </div>
  </div>
</template>

<script>
import uniqueId from "@/mixins/unique-id.js";

export default {
  name: "SettingsRangeButton",
  mixins: [uniqueId],
  props: {
    option: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let { label, range } = this.option;

    // Ensure range exists
    if (!range || !Array.isArray(range)) range = [1];

    return {
      label,
      range,
    };
  },
  computed: {
    gridTemplateColumns() {
      let size = this.option?.range?.length || 1;

      return `repeat(${size}, 1fr)`;
    },
    value() {
      return this.option?.value;
    },
  },
};
</script>

<style lang="scss">
.settings__option {
  &__range-button {
    padding: 0.3em 0;

    &__grid {
      display: grid;
      grid-gap: 0;
      border: 1px solid var(--faded-text);
      border-radius: 0.4em;
      align-items: stretch;
      overflow: hidden;
      margin: 0 0 0.6em;
      background: var(--input-bg);
      height: 2.8em;
      box-sizing: border-box;
    }

    &__button {
      margin: 0;
      height: auto;
      padding: 0 0.4em;
      background: transparent;
      color: var(--text);
      border-radius: 0;
      border: 0;
      border-left: 1px solid var(--faded-text);

      &--active {
        font-weight: bolder;
        background: var(--input-bg-hover);
        box-shadow: inset 0 2px 4px var(--faded-text);
      }

      &:first-child {
        border-left: 0;
      }

      &:hover {
        background: var(--colour);
        color: white;
      }

      &--active:hover {
        background: var(--input-bg-hover);
        color: var(--text);
      }
    }
  }
}
</style>