<template>
  <div class="form__label" :class="[labelClass, details.classname]">
    <InputCheckbox
      aria-label="Checkbox"
      :label="details.label"
      :required="details.required"
      :checked="value"
      :class="inputClass"
      @change="updateInput"
      v-model="value"
    />
  </div>
</template>

<script>
import defaultData from "./__mixins.js";
import InputCheckbox from "@/components/ui/InputCheckbox.vue";

export default {
  name: "CheckboxInput",
  mixins: [defaultData],
  components: {
    InputCheckbox,
  },
};
</script>

<style lang="scss"></style>