<template>
  <div
    class="modal"
    id="modal"
    tabindex="0"
    @keydown.escape.prevent="hideModal"
    :class="{ 'modal--transition-out': transitioning }"
  >
    <div class="modal__flexer">
      <!-- <FocusLock> -->
      <div
        class="modal__window"
        :class="`modal__window--${type.toLowerCase()}`"
        ref="popup"
        :aria-hidden="transitioning"
      >
        <h3 v-if="resolve" class="modal__window__title">{{ title }}</h3>

        <div v-if="content" class="modal__window__content">
          {{ content }}
        </div>

        <FormView
          v-if="form"
          :contentProp="form"
          :cancelable="true"
          class="modal__window__form"
          @cancel-form="hideModal"
          @submit-form="confirmFormPromise"
        ></FormView>

        <div v-if="resolve && !form" class="modal__confirmation">
          <button
            class="modal__confirmation__button modal__confirmation__button--cancel button button--cancel"
            @click="hideModal"
          >
            {{ cancelButton }}
          </button>
          <button
            class="modal__confirmation__button modal__confirmation__button--confirm button button--confirm"
            @click="confirmPromise"
          >
            {{ confirmButton }}
          </button>
        </div>

        <button
          class="modal__cross"
          aria-label="Close modal"
          aria-controls="modal"
          aria-expanded="true"
          @click="hideModal"
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            class="modal__cross__icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Close cross</title>
            <path
              d="M1 1L5.25 5.25M9.5 9.5L5.25 5.25M5.25 5.25L9.5 1M5.25 5.25L1 9.5"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
      <!-- </FocusLock> -->
    </div>
  </div>
</template>

<script>
import FocusLock from "vue-focus-lock";
import clickout from "@/mixins/clickout.js";
import overflow from "@/mixins/overflow.js";
import FormView from "@/components/formviewer/FormView.vue";

export default {
  name: "ModalWindow",
  mixins: [clickout, overflow],
  components: {
    FormView,
    // FocusLock,
  },
  data() {
    return {
      disabled: true,
      transitioning: false,
    };
  },
  props: {
    content: {
      type: String,
      default: null,
    },
    form: {
      type: Object,
      default: () => {},
    },
    resolve: {
      default: null,
    },
    buttons: {
      default: null,
    },
    type: {
      type: String,
      default: "modal",
      validator: (value) => {
        return ["modal", "confirm", "form"].indexOf(value) !== -1;
      },
    },
  },
  mounted() {
    this.overflow = false;

    setTimeout(() => {
      this.disabled = false;
    }, 200);
  },
  beforeUnmount() {
    this.overflow = true;
  },
  computed: {
    cancelButton() {
      return this.buttons?.cancel || "Cancel";
    },
    confirmButton() {
      return this.buttons?.confirm || "Confirm";
    },
    title() {
      return this.type;
    },
  },
  methods: {
    confirmPromise(e) {
      this.hideModal(e, true);
    },
    confirmFormPromise(data) {
      this.hideModal(null, data);
    },
    hideModal(e, resolve = false) {
      if (this.disabled) return;

      // Toggle modal settings
      this.transitioning = true;
      this.disabled = true;

      // Resolve/reject any existing promises
      if (this.resolve) this.resolve(resolve);

      // Once fadeOut animation has completed, remove modal
      setTimeout(() => {
        this.transitioning = false;

        // Clear modal content
        this.$store.dispatch("hideModal");
      }, 200);
    },
    clickOutside(e) {
      if (this.$refs.popup.contains(e.target)) return;

      // Hide modal
      this.hideModal();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/settings/extends.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;
  animation: fadeIn 0.15s ease-out;
  transition: opacity 0.15s ease-out;
  overflow: auto;
  background: rgba(#000, 0.6);
  cursor: pointer;

  &__flexer {
    padding: 5vw 0 calc(2em + 5vw);
    width: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
  }

  &__window {
    @extend %radiusbox;
    position: relative;
    background: var(--background);
    animation: fadeUp 0.15s ease-out;
    transition: transform 0.15s ease-out;
    width: 92%;
    max-width: 38em;
    box-sizing: border-box;
    cursor: default;
    pointer-events: auto;
    padding: 1.6em;

    &--confirm {
      max-width: 24em;
    }

    &--form {
      max-width: 38em;
      padding: 2.4em;
    }

    &__title {
      font-size: 1.25em;
      color: var(--faded-text);
      text-transform: capitalize;
      margin: 0 0 0.5rem;
    }

    &__content {
      padding: 0 0.5em;
      line-height: 1.55em;
    }

    &__form {
      margin: 1em 0 0;
    }
  }

  &__confirmation {
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;

    &__button {
      margin: 0 0.35em;
      padding: 0.7em 1.2em;
    }
  }

  &__cross {
    position: absolute;
    top: 0.3em;
    right: 0.3em;
    margin: 0;
    padding: 0.6em;
    width: auto;
    height: auto;
    background: transparent;
    color: var(--faded-text-darker);

    &:hover {
      background: transparent;
      color: var(--colour);
    }

    &__icon {
      display: block;
      width: 0.8em;
      height: 0.8em;
      margin: 0;
      stroke: currentColor;
    }
  }

  &--transition-out {
    opacity: 0;
  }

  &--transition-out &__window {
    transform: translate(0, 2em);
  }
}
</style>