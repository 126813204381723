// Type input
export default {
  name: 'value',
  label: 'Default value',
  type: 'text',
  value: '',
  placeholder: 'Default value for column',
  required: false,
  conditions: [
    {
      input: 'type',
      values: ['text', 'textarea', 'number']
    }
  ]
};
