import {createApp} from 'vue';
import {store} from './state/store.js';
import App from './App.vue';
import './registerServiceWorker';

// Create app
const app = createApp(App);

// Add state and mount app
app.use(store);
app.mount('#app');
