<template>
  <a :href="href" @click="check">
    <slot></slot>
  </a>
</template>

<script>
import go from "@/mixins/go.js";

export default {
  name: "VueLink",
  props: {
    href: {
      type: String,
    },
  },
  mixins: [go],
  methods: {
    check(e) {
      this.go(e, this.href);
    },
  },
};
</script>
