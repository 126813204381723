<template>
  <button class="settings__option__listings-button" @click="clickAction">
    {{ option.text }}
    <SvgIcon
      v-if="option.icon"
      :name="option.icon"
      :title="option.text"
      class="settings__option__listings-button__icon"
    />
  </button>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon.vue";

export default {
  name: "SettingsListingButton",
  components: {
    SvgIcon,
  },
  props: {
    option: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    clickAction() {
      this.$emit("option-updated", this.option);
    },
  },
};
</script>

<style lang="scss">
.settings__option {
  &__listings-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    color: var(--text);
    padding: 0.5em 0;
    line-height: 1.4em;
    border-radius: 0;
    transition: color 0.15s;
    font-size: 0.89em;
    width: 100%;

    &:hover {
      background: transparent;
      color: var(--colour);
    }

    &__icon {
      display: block;
      width: 1.4em;
      height: 1.4em;
      margin: 0 0 0 1.5em;
      stroke: var(--colour);
      stroke-width: 1.75px;
      fill: none;
    }
  }
}
</style>