<template>
  <div class="calendar">Calendar component</div>
</template>

<script>
export default {
  name: "DashboardCalendar",
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.calendar {
  color: orangered;
}
</style>