<template>
  <div
    class="form__fields"
    :class="[this.details?.classname]"
    :hidden="groupHidden"
    aria-live="polite"
  >
    <h2 class="form__fields__title" v-if="title">{{ title }}</h2>

    <div class="form__fields__grid" :style="gridsize">
      <component
        v-for="(el, index) of content"
        :key="el.name"
        :contentProp="el.content"
        :detailsProp="el.details"
        :titleProp="el.title"
        :inputKeys="inputKeys"
        :is="el._is"
        :hidden="hidden || el._hidden"
        @update-form="(data) => syncData(index, { ...el, ...data })"
      />
    </div>
  </div>
</template>

<script>
import FormText from "./inputs/text.vue";
import FormTextarea from "./inputs/textarea.vue";
import FormNumber from "./inputs/number.vue";
import FormDropdown from "./inputs/dropdown.vue";
import FormCheckbox from "./inputs/checkbox.vue";
import FormToggle from "./inputs/toggle.vue";
import FormMultitags from "./inputs/multitags.vue";

// Autobuilder-exclusive components
import AutobuilderDropdown from "./inputs/autobuilder/dropdown.vue";
import AutobuilderConditions from "./inputs/autobuilder/conditions.vue";

export default {
  name: "FormGroup",
  components: {
    "form-text": FormText,
    "form-textarea": FormTextarea,
    "form-dropdown": FormDropdown,
    "form-checkbox": FormCheckbox,
    "form-toggle": FormToggle,
    "form-number": FormNumber,
    "form-multitags": FormMultitags,
    "form-autobuilder-dropdown": AutobuilderDropdown,
    "form-autobuilder-conditions": AutobuilderConditions,
  },
  props: {
    detailsProp: {
      type: Object,
      default: () => {},
    },
    titleProp: {
      type: String,
      default: "",
    },
    contentProp: {
      type: Array,
      default: () => [],
    },
    inputKeys: {
      type: Array,
      default: () => [],
    },
    hidden: {
      type: Boolean,
    },
  },
  computed: {
    title() {
      return this.titleProp;
    },
    content() {
      return [...this.contentProp];
    },
    details() {
      return { ...this.detailsProp };
    },
    gridsize() {
      let columns = this.details?.gridsize || 1;

      return `grid-template-columns: repeat(${columns}, 1fr)`;
    },
    groupHidden() {
      return this.details?._grouphide;
    },
  },
  methods: {
    syncData(index, data) {
      this.content.splice(index, 1, data);

      // Sync data
      this.$emit("update-form", { content: this.content });
    },
  },
};
</script>

<style lang="scss">
.form__fields {
  &__title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 1rem;
  }

  &__grid {
    display: grid;
    grid-column-gap: 1.5em;
  }

  &__grid & {
    margin: 2.2em 0;
  }
}
</style>