<template>
  <section class="formbuilder">
    <h1 v-if="content.title" class="formbuilder__title">{{ content.title }}</h1>
    <p v-if="content.description" class="formbuilder__description">
      {{ content.description }}
    </p>

    <FormView
      :contentProp="content.form"
      :cancelable="false"
      @submit-form="prepareForServer"
    />
  </section>
</template>

<script>
import FormView from "./FormView.vue";
import postData from "@/mixins/post.js";

export default {
  name: "FormViewer",
  mixins: [postData],
  components: {
    FormView,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    prepareForServer(data) {
      console.log("Post to server", JSON.parse(JSON.stringify(data)));
    },
    // postToServer(url, data);
  },
};
</script>

<style lang="scss">
.formbuilder__add {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1.5em 0 0;

  &__button {
    margin: 0 0 0 0.8em;
  }
}
</style>