/**
 *  Post to server
 * 
 */
export default {
  methods: {
    postToServer(url, data) {
      console.log(`Post to ${url}`, data);

      this.$store.dispatch('toast', {
        error: true,
        content: 'Server not yet connected',
        timeout: 3000
      });
    }
  }
};
