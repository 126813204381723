<template>
  <div class="graph">graph component</div>
</template>

<script>
export default {
  name: "DashboardGraph",
  props: {
    content: {
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.graph {
  color: purple;
}
</style>