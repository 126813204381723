/**
 *  Get target value from array of cells
 * 
 *  @param {Number} index
 *  @param {Array} table
 */
export default function(index, table = {}){
  try {
    // Fetch target cell
    let targetCell = table.td[index];

    // Get value from target cell
    let cellValue = Object.values(targetCell)[0];

    // If value is an object, attempt to get value from object
    if (cellValue instanceof Object) cellValue = cellValue.value;

    // Return value as string/number, as appropriate
    return isNaN(cellValue) ? String(cellValue) : Number(cellValue);
  } catch (e) {
    return null;
  }
}
