<template>
  <div
    class="listings__row"
    :class="{
      'listings__row--inactive': !filters.active,
      'listings__row--checked': filters.checked,
    }"
    aria-checked="false"
    role="row"
  >
    <div
      v-if="leftCol"
      class="listings__cell listings__cell--overflow"
      :class="{
        'listings__cell--checkbox': settings.multiselect,
        'listings__cell--hasthumb': settings.draggable,
      }"
      role="column"
    >
      <div
        v-if="settings.draggable"
        class="listings__cell__thumb"
        tabindex="0"
        aria-label="Re-order listings table rows using your arrow keys"
        role="Foo"
      ></div>

      <InputCheckbox
        v-if="settings.multiselect"
        :checked="filters.checked"
        @input="rowChecked"
        aria-label="Check row"
      />
    </div>

    <component
      :is="actions?.url ? 'a' : 'div'"
      :href="actions?.url"
      :title="actions?.title"
      class="listings__row__content"
      :class="{
        'listings__row--padleft': !leftCol,
        'listings__row--padright': !rightCol,
      }"
    >
      <ListingsTableCell
        v-for="(cell, index) of cells"
        :key="index"
        :content="cell"
      />
    </component>

    <div
      v-if="rightCol"
      class="listings__cell listings__cell--more listings__cell--overflow"
      role="column"
    >
      <div class="listings__force-center">
        <InputToggle
          v-if="settings.toggle"
          :checked="filters.active"
          @input="rowToggled"
          aria-label="Toggle row active/inactive"
        />
        <SettingsPopup
          v-if="settings.buttons && settings.buttons.length"
          :options="settings.buttons"
          :narrow="true"
          @option-updated="settingsAction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListingsTableCell from "./table-cell.vue";
import InputToggle from "@/components/ui/InputToggle.vue";
import InputCheckbox from "@/components/ui/InputCheckbox.vue";
import SettingsPopup from "@/components/ui/SettingsPopup.vue";

export default {
  name: "ListingsTableRow",
  props: {
    settings: {
      default: () => {},
      required: true,
    },
    cells: {
      default: () => {},
      required: true,
    },
    filters: {
      default: () => {},
      required: true,
    },
    actions: {
      default: () => {},
    },
    rowId: {
      type: [String, Number],
    },
    leftCol: {
      type: Boolean,
      default: false,
    },
    rightCol: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ListingsTableCell,
    InputToggle,
    InputCheckbox,
    SettingsPopup,
  },
  methods: {
    rowToggled(e) {
      this.$emit("row-toggled", {
        active: e,
        rowId: this.rowId,
      });
    },
    rowChecked(e) {
      this.$emit("row-checked", {
        checked: e,
        rowId: this.rowId,
      });
    },
    settingsAction(e) {
      let { action, url, confirm } = e;

      // Emit action
      this.$emit("row-action", {
        ...{ row: this.rowId },
        ...{ action, url, confirm },
      });
    },
  },
};
</script>

<style lang="scss">
.listings {
  $checkedbg: #e7f5e4;
  $checked: #1bc36f;
  $oddrow: #edf2f5;
  $hover: #d7e1f1;

  // Root colours
  --checked: #{$checked};
  --checked-bg: #{$checkedbg};
  --checked-bg-darken: #{darken($checkedbg, 5%)};
  --oddrow: #{$oddrow};
  --hover: #{$hover};

  // Main cell styling
  &__row {
    display: table-row;

    &--inactive {
      text-decoration: line-through;
      color: var(--faded-text);
    }

    &--inactive .listings__cell--id {
      color: inherit;
    }

    &--checked {
      background: var(--checked-bg);
    }

    &--archived {
      color: var(--error);
      text-decoration: line-through;

      input-toggle {
        visibility: hidden;
      }
    }

    // Alternate background colour for rows
    &:nth-of-type(even) {
      background-color: var(--oddrow);
    }

    &--checked:nth-of-type(even) {
      background: var(--checked-bg-darken);
    }
  }

  &__row--checked > &__cell:first-of-type {
    box-shadow: inset 4px 0 0 0 var(--checked);
  }

  &__cell {
    color: inherit;

    &__thumb {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: transparent url("/img/drag.svg") no-repeat 66% 50%;
      cursor: grab;
      width: 1.2rem;
      padding: 0;
      opacity: 0.3;
      transition: opacity 0.15s;

      &:hover:not(:active) {
        opacity: 1;
        box-shadow: -4px 0 0 var(--colour);
      }
    }
  }

  // Sortable hover
  &__row.sortable-chosen &__cell__thumb {
    opacity: 0;
  }

  // Sortable selected
  &__row.sortable-chosen {
    outline: 2px dashed var(--faded-text);
  }

  // T-body hover states
  &__tbody &__row:not(.listings__row--checked) {
    transition: background-color 0.15s;

    &:hover {
      background-color: var(--hover);
    }
  }

  // Flexers
  &__force-center {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  // Toggle boxes
  &__tbody {
    .checkbox__tickbox,
    .togglebox__icon {
      margin: 0;
    }
  }

  // Link cells
  &__row__content {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    .listings__cell--id {
      transition: color 0.15s;
    }
  }
}
</style>