
/**
 *  Check if element should be hidden, either conditionally or explicitly
 * 
 *  @param {Object} details
 *  @param {Array} allElements
 */
export default function checkIsHidden(details = {}, allElements) {
  let {hidden, conditions, allconditions} = details;

  // Return 'hidden' value if hidden
  if(hidden) return hidden;

  // If no conditions exist, ignore
  if (emptyConditions(conditions)) return false;

  // Monitor matched conditions
  let matches = 0;

  // Loop through each condition and check values
  for (let {input, values} of conditions) {
    let conditionMet = checkConditionsMet({input, values}, allElements);

    // Record if condition is met
    if (conditionMet) matches++;
  }

  // If only one condition is required, hide=true for no matches
  if(!allconditions) return !matches;

  // If all conditions are required, hide=true if matches !== conditions.length
  return Boolean(matches !== conditions.length);
}

/**
 *  Find any corresponding conditions
 * 
 *  @param {Object} element
 *  @param {Array} allElements
 */
export function checkConditionsMet(element, allElements) {
  let input = allElements.filter((n) => n.details?.name === element.input);

  // Return input matches
  return checkMatchValues(input[0]?.details?.value, element.values);
}

/**
 *  Check for matching values from condition
 * 
 *  @param {String} value
 *  @param {Array} possibilities
 */
export function checkMatchValues(value, possibilities = null) {
  if(possibilities === null) return false;

  try {
    return value === possibilities || possibilities.includes(value);
  } catch (e) {
    return false;
  }
}

/**
 *  Check if conditions exists, and is not empty
 * 
 *  @param {Object} conditions
 */
export function emptyConditions(conditions = null) {
  return !(Array.isArray(conditions) && conditions.length);
}