<template>
  <div
    class="toolbar"
    role="navigation"
    aria-label="Toolbar and breadcrumb trail"
  >
    <VueLink
      v-if="previous"
      :href="previous.href"
      :title="previous.title"
      class="toolbar__back"
      >Back</VueLink
    >

    <nav
      v-if="menu && menu.length"
      aria-label="Breadcrumb"
      class="toolbar__breadcrumb"
    >
      <ol class="toolbar__breadcrumb__list">
        <li
          class="toolbar__breadcrumb__listitem"
          v-for="(item, i) of menu"
          :key="i"
        >
          <VueLink
            class="toolbar__breadcrumb__link"
            :href="item.href"
            :title="item.title"
            :aria-current="i === menu.length - 1 ? 'page' : null"
            >{{ item.name }}</VueLink
          >
        </li>
      </ol>
    </nav>

    <div class="toolbar__tools">
      <InputToggle
        v-if="toggleable"
        class="toolbar__toggle"
        label="Active"
        aria-label="Toggle page as active"
        :rtl="true"
        tabindex="0"
        @input="togglePage"
      />
      <button
        v-if="previewable"
        class="toolbar__button toolbar__button--preview"
        aria-label="Quick preview"
        data-tooltip="Quick preview"
        type="button"
        role="button"
        @click="previewPage"
      >
        <SvgIcon name="preview" title="Delete icon" class="toolbar__icon" />
      </button>
      <button
        v-if="removable"
        class="toolbar__button toolbar__button--delete"
        aria-label="Delete page"
        data-tooltip="Delete page"
        type="button"
        role="button"
        @click="deletePage"
      >
        <SvgIcon name="delete" title="Delete icon" class="toolbar__icon" />
      </button>
      <button
        class="toolbar__button toolbar__button--save"
        :disabled="!unsaved"
        type="button"
        role="button"
        aria-label="Button"
        @click="savePage"
      >
        {{ unsaved ? "Save" : "Saved" }}
      </button>
    </div>
  </div>
</template>

<script>
import VueLink from "@/router/VueLink.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import InputToggle from "@/components/ui/InputToggle.vue";
import { mapState } from "vuex";

export default {
  name: "DashboardToolbar",
  components: {
    VueLink,
    SvgIcon,
    InputToggle,
  },
  data() {
    return {
      unsaved: false,
    };
  },
  computed: mapState({
    previous: (state) => state.toolbar?.previous,
    toggleable: (state) => state.toolbar?.toggleable,
    previewable: (state) => state.toolbar?.previewable,
    removable: (state) => state.toolbar?.removable,
    menu: (state) => state.toolbar?.breadcrumb,
  }),
  methods: {
    togglePage(e) {
      this.$emit("toolbar-edit", { action: "toggle", value: e });
    },
    previewPage() {
      this.$emit("toolbar-edit", { action: "preview" });
    },
    deletePage() {
      this.$emit("toolbar-edit", { action: "delete" });
    },
    savePage() {
      this.$emit("toolbar-save");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/sizes";
@import "~@/assets/scss/settings/extends.scss";

// Main
$cmstools-back-arrow: 9px;
$cmstools-icon-size: 28px;
$cmstools-icon-stroke: 1.5px;

// Button colours
$cmstools-button-bg: darken(#2b6ce5, 7%);

.toolbar {
  @extend %shadowbox;
  @extend %radiusbox;

  position: sticky;
  top: $header-height;
  margin: 0 0 $guttering;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: var(--colour) url("~@/assets/images/motion.svg") no-repeat 50% 70%;
  background-size: cover;
  color: white;
  min-height: 5em;
  z-index: 10;
  box-shadow: 0 #{-$header-height} #{0.5 * $header-height} $header-height white;

  &:empty {
    display: none;
  }

  &__toggle,
  &__button {
    background: $cmstools-button-bg;
    color: white;
    border-radius: var(--input-radius);
    font-weight: 500;
    font-size: 0.95em;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1em;
    min-width: 2.8em;
    min-height: 2.8em;

    &:hover {
      background-color: $cmstools-button-bg;
    }
  }

  &__back {
    @extend %radiusbox;

    position: relative;
    padding: 0;
    width: #{$guttering * 2};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: background-color 0.15s;
    white-space: nowrap;
    overflow: hidden;
    text-indent: 110%;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - #{$cmstools-back-arrow / 2});
      left: calc(52% - #{$cmstools-back-arrow / 2});
      width: $cmstools-back-arrow;
      height: $cmstools-back-arrow;
      border-top: 2px solid white;
      border-left: 2px solid white;
      transform: rotate(-45deg);
    }

    &:hover {
      background-color: $cmstools-button-bg;
    }
  }

  &__breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto 0 0;
    padding: 0 0 0 #{$guttering / 2};
    color: var(--light-blue);

    &:first-child {
      padding-left: $guttering;
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }

    &__listitem {
      padding: 0;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 11em;

      &:before {
        content: "/";
        margin: 0 0.1rem;
      }

      &:first-child:before {
        content: "../";
        margin-left: 0;
      }
    }

    &__link {
      font-weight: 500;
      color: var(--light-blue);

      &:hover {
        color: white;
      }
    }

    &__listitem:last-child &__link {
      color: white;
    }
  }

  &__tools {
    padding: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
  }

  &__toggle {
    padding: 0 0.7em 0 1em;
    cursor: pointer;
  }

  &__button {
    margin: 0 0 0 0.6em;

    &--delete,
    &--preview {
      padding: 0;
    }

    /* &--delete {
        background: #d40c37;
      } */

    &--save {
      padding: 0.9em 1.2em 0.9em 1em;
      color: white;

      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-color: var(--input-warning);
        border-radius: 100%;
        margin: 0 0.5em 0 0;
      }

      &[disabled] {
        color: var(--light-blue);
        cursor: default;

        &:before {
          width: 4px;
          height: 8px;
          background-color: transparent;
          border: 2px solid var(--light-blue);
          border-top-color: transparent;
          border-left-color: transparent;
          transform: rotate(45deg) translate(-2px);
          border-radius: 2px;
          margin: 0 0.6em 0 0.05em;
        }
      }
    }
  }

  &__icon {
    display: block;
    width: $cmstools-icon-size;
    height: $cmstools-icon-size;
    fill: transparent;
    stroke: currentColor;
    stroke-width: 1.5px;
    stroke-linejoin: round;
    stroke-linecap: round;
  }

  .togglebox__handle {
    fill: white;
  }

  .togglebox:hover .togglebox__icon:not([disabled="true"]) .togglebox__handle {
    fill: white;
  }
}
</style>
