<template>
  <div class="motion" @keydown.tab="addTab" @click="removeTab" v-if="firstLoad">
    <MotionLogin v-if="view === 'login' || blocked" />
    <MotionDashboard v-else-if="view === 'dashboard'" />
    <h1 class="motion__load-text" v-else>
      {{ loading ? "Loading content" : "Unable to load requested view" }}
    </h1>

    <NoticeWindow />
  </div>
</template>

<script>
import MotionLogin from "@/pages/Login.vue";
import MotionDashboard from "@/pages/Dashboard.vue";
import NoticeWindow from "@/components/notifications/";
import { mapState } from "vuex";

export default {
  name: "MotionCMS",
  computed: mapState({
    firstLoad: (state) => state.firstLoad,
    loading: (state) => state.loading,
    view: (state) => state.view,
    blocked: (state) => state.authorisation?.blocked,
  }),
  components: {
    MotionLogin,
    MotionDashboard,
    NoticeWindow,
  },
  methods: {
    addTab() {
      document.documentElement.classList.add("html--tab");
    },
    removeTab() {
      document.documentElement.classList.remove("html--tab");
    },
  },
  mounted() {
    let initialPage = window.location.path;

    // Add popstate monitor
    window.addEventListener("popstate", (event) => {
      this.$store.dispatch("pop", {
        url: event.state?.url || initialPage,
      });
    });

    // Perform initial state load
    this.$store.dispatch("fetch", {
      url: "/load/", // window.location.path,
      body: { firstLoad: true },
    });
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/style";

.motion {
  &__load-text {
    text-align: center;
    margin: 0 auto;
    padding: 3em;
    font-size: 1.1em;
    font-weight: normal;
    color: var(--light-text);
  }
}
</style>
