<template>
  <div class="login">
    <div class="login__modal">
      <div class="login__modal__padding">
        <VueLink
          class="login__logo login__link"
          href="/"
          title="Back to login page"
        >
          <img
            class="login__logo__image"
            src="~@/assets/images/logo.svg"
            alt="Motion CMS logo"
          />
        </VueLink>

        <hr class="login__divider" />

        <form
          class="login__form"
          method="post"
          autocomplete="off"
          novalidate
          :class="{
            'login__form--twofactor': twofactor,
            'login__form--reset': blocked || forgotten,
            'login__form--error': error,
            'login__form--loading': loading,
            'login__form--success': success,
          }"
          @submit="checkForm"
        >
          <div v-if="blocked" class="login__blocked">
            <h1 class="login__title">Blocked</h1>

            <p class="login__content">
              You have entered an incorrect password 8 times or more, and have
              now been blocked for 24 hours. To try again before them, please
              reset your password...
            </p>
          </div>

          <fieldset v-if="blocked || forgotten">
            <legend>Reset your pasword</legend>

            <label class="login__label">
              Email address
              <input
                placeholder="Email address"
                name="email"
                tabindex="1"
                type="email"
                aria-label="Email address"
                class="login__input"
                required
              />
            </label>

            <div class="login__buttons">
              <button
                name="submit"
                tabindex="2"
                class="button--fancy login__button login__button--submit"
                type="submit"
                aria-label="Button"
              >
                Reset password
              </button>

              <VueLink
                v-if="!blocked"
                href="/"
                title="Reset your password"
                tabindex="5"
                class="login__link"
                >Back to login</VueLink
              >
            </div>
          </fieldset>

          <fieldset v-else-if="twofactor">
            <legend>Two-factor verification</legend>

            <h1 class="login__title">Two-factor</h1>

            <p class="login__content">
              Please check your two-factor authentication app or device for a
              6-digit code, then simply enter it below:
            </p>

            <input
              type="number"
              placeholder="000000"
              pattern="[0-9]{6}"
              name="twofactor"
              tabindex="1"
              class="login__input--number"
              aria-label="Enter your two-factor verification code here"
              required
            />

            <div class="login__buttons">
              <button
                name="submit"
                tabindex="2"
                class="button--fancy login__button login__button--submit"
                type="submit"
                aria-label="Button"
              >
                Authenticate
              </button>
            </div>
          </fieldset>

          <fieldset v-else>
            <legend>Log in</legend>

            <label class="login__label">
              Username
              <input
                placeholder="Username"
                name="username"
                tabindex="1"
                type="text"
                aria-label="Username"
                class="login__input"
                required
              />
            </label>

            <label class="login__label">
              Password
              <input
                placeholder="Password"
                name="password"
                tabindex="2"
                type="password"
                aria-label="Password"
                class="login__input"
                required
              />
            </label>

            <InputCheckbox
              label="Keep me logged in"
              aria-label="Check here to remember password on this device"
              :rtl="true"
              name="remember"
              tabindex="3"
              role="checkbox"
              class="login__checkbox"
            />

            <div class="login__buttons">
              <button
                name="submit"
                tabindex="4"
                class="button--fancy login__button login__button--submit"
                type="submit"
                aria-label="Button"
              >
                Login
              </button>

              <VueLink
                href="forgot"
                title="Reset your password"
                tabindex="5"
                class="login__link"
                >Reset password</VueLink
              >
            </div>
          </fieldset>
        </form>
      </div>

      <div class="login__webauthn" v-if="!blocked">
        <button
          title="Log in with WebAuthn"
          tabindex="6"
          type="button"
          aria-label="Button"
          class="login__button login__button--webauthn"
        >
          Log in with WebAuthn
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueLink from "@/router/VueLink.vue";
import InputCheckbox from "@/components/ui/InputCheckbox.vue";
import { mapState } from "vuex";

export default {
  name: "MotionLogin",
  components: {
    InputCheckbox,
    VueLink,
  },
  data: function () {
    return {
      twofactor: false,
      forgotten: false,
      loading: false,
      success: false,
      error: false,
    };
  },
  computed: mapState(["blocked", "settings"]),
  methods: {
    checkForm(e) {
      e.preventDefault();

      console.log(e);
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4rem 0 6rem;
  background: var(--colour) url("~@/assets/images/motion.svg") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  animation-fill-mode: backwards;

  &__modal {
    border-radius: 1.4rem;
    text-align: center;
    width: 90%;
    max-width: 23rem;
    background: white;
    box-sizing: border-box;
    box-shadow: 0 20px 65px -10px rgba(#17245c, 0.4),
      0 5px 5px -3px rgba(#17245c, 0.2);
    animation: fadeUpLarge 0.7s cubic-bezier(0.005, 0.8, 0.4, 0.995);
    animation-fill-mode: backwards;
    overflow: hidden;

    &__padding {
      padding: 2.2rem;

      @media (max-width: 700px) {
        padding: 1.6rem;
      }
    }
  }

  &__logo {
    text-align: center;
    margin: 0 0 1.8rem;

    &__image {
      display: block;
      margin: 0 auto;
      width: #{14.3em * 0.8};
      height: #{3em * 0.8};
    }
  }

  &__divider {
    border: 0;
    border-top: 2px solid var(--light-grey);
    margin: 1.5em 0;
    padding: 0;
  }

  &__title {
    font-size: 1.4rem;
    margin: 0 0 0.8rem;
  }

  &__content {
    margin: 0 0 1.5rem;
    font-weight: 500;
    display: block;
    font-size: 0.95rem;
    line-height: 1.45em;
    color: var(--light-text);
    letter-spacing: inherit;
  }

  &__label {
    margin: 0 0 0.8rem;
  }

  &__checkbox {
    font-size: 0.85rem;
    line-height: 1.2em;
    text-align: left;
    color: var(--light-text);
    cursor: pointer;
  }

  &__link {
    display: block;
    font-weight: 500;
    font-size: 0.85em;
    line-height: 1.2em;
    color: var(--light-text);
    transition: color 0.15s;
    cursor: pointer;

    &:hover {
      color: var(--colour);
    }
  }

  &__buttons {
    margin: 1.6rem 0 0;
  }

  &__button {
    margin: 0;

    &--submit {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      line-height: 3rem;
      width: 8rem;
      min-width: 3rem;
      padding: 0;
      overflow: hidden;
      white-space: nowrap;
      background-color: var(--colour);
      background-position: center;
      background-repeat: no-repeat;
      transition: all 0.2s ease-in-out;
      margin: 0 auto 1rem;
    }

    &--webauthn {
      display: inline-block;
      box-sizing: border-box;
      box-shadow: none;
      background: var(--input-bg);
      color: var(--light-text);
      font-size: 0.9rem;
      padding: 0.8em 1.6em;
      border-radius: var(--input-radius);
      transition: border-color 0.15s, background-color 0.15s, color 0.15s;

      &:hover {
        background: var(--input-bg-hover);
        box-shadow: none;
        color: var(--text);
      }
    }
  }

  &__webauthn {
    padding: 0.85em;
    background: var(--input-bg);

    @media (max-width: 700px) {
      padding: 1.2em 1.6rem;
    }
  }

  &__blocked {
    background: var(--input-error);
    color: white;
    text-align: left;
    padding: 1em 1.2em;
    border-radius: var(--input-radius);
    margin: 0 0 1.5rem;
  }

  &__blocked &__title {
    color: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 0 0.5rem;
  }

  &__blocked &__content {
    font-size: 0.85rem;
    margin: 0;
    color: inherit;
  }

  &__form--twofactor &__input--number {
    text-align: center;
    font-size: 1.7em;
    line-height: 1.2em;
    padding: 0.7em 0;
    letter-spacing: 0.2em;
    width: 7.5em;
    height: 2.3em;
  }

  &__form--twofactor &__button--submit {
    width: 10em;
  }

  &__form--reset &__button--submit {
    width: 11.5em;
  }

  &__form--error &__button--submit {
    background: var(--input-error);
    animation: loginErrorWiggle 0.35s linear 0.2s;
    pointer-events: none;
    padding: 0 0.2rem 0 0;
    width: 7em;

    &::before {
      content: "×";
      font-size: 1.8em;
      margin: 0 0.6rem 0 0;
    }
  }

  &__form--loading &__button--submit {
    pointer-events: none;
    width: 3rem;
    background: var(--colour) url("~@/assets/images/loading-white.svg")
      no-repeat center;
    background-size: 2.6rem 2.6rem;
    text-indent: 7em;
    color: rgba(var(--colour), 0);
  }

  &__form--success &__button--submit {
    background: var(--input-success) url("~@/assets/images/tick.svg") no-repeat
      center;
    background-size: 1.8em 1.8em;
    pointer-events: none;
    width: 3em;
    text-indent: 7em;
  }
}

@keyframes loginErrorWiggle {
  20%,
  50%,
  80% {
    transform: rotate(1deg) translate(3px);
  }

  35%,
  55% {
    transform: rotate(-1deg) translate(-3px);
  }
}
</style>
