<template>
  <div class="listings__cell" :class="targetClass" role="column">
    <span
      v-if="format === 'range'"
      class="listings__cell__range"
      role="range"
      aria-readonly="true"
      :aria-valuenow="rangeValue.value"
      :aria-valuemin="rangeValue.min"
      :aria-valuemax="rangeValue.max"
      :data-tooltip="rangeValue.percent"
      ><span
        class="range--slide"
        :style="{ width: rangeValue.percent, backgroundColor: rangeColour }"
      ></span
      ><span class="range--hover"></span
    ></span>
    <template v-else>{{ output }}</template>
  </div>
</template>

<script>
export default {
  name: "ListingsTableCell",
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let [format, output] = this.content ? Object.entries(this.content)[0] : [];

    // Create classname
    let targetClass = `listings__cell--${format || "empty"}`;

    // Allow override for required formats
    let allowOverflow = ["range", "graph"].includes(format);

    // Add appropriate overflow class
    if (allowOverflow) targetClass += " listings__cell--overflow";

    // Return
    return {
      format,
      output,
      targetClass,
    };
  },
  computed: {
    rangeValue() {
      let output = this.output;
      let { value, min, max } = isNaN(output) ? output : { value: output };

      // Default min/max
      if (!min) min = 0;
      if (!max) max = 100;

      // Ensure min is create than max
      let trueMin = Math.min(value, Math.min(min, max));
      let trueMax = Math.max(value, Math.max(min, max));

      // Return formatted range
      return {
        value,
        min: trueMin,
        max: trueMax,
        percent: `${Math.round(100 * (value / trueMax))}%`,
      };
    },
    rangeColour() {
      return this.output?.colour;
    },
  },
};
</script>

<style lang="scss">
// Link hover colour
.listings {
  &__row &__row__content[href]:hover &__cell--id {
    color: var(--faded-text-darker);
  }

  &__row:not(&__row--inactive) &__row__content[href]:hover &__cell--id {
    color: var(--colour);
  }

  // Types
  &__cell {
    overflow: hidden;
    display: table-cell;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10em;
    padding: 0.8rem 0.5rem;
    vertical-align: middle;
    min-height: 1.4em;
    line-height: 1.4em;
    box-sizing: border-box;

    &--id {
      font-weight: 600;
      color: var(--text);
    }

    &--range {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &--number {
      text-align: right;
    }

    &--more {
      text-align: right;
      overflow: visible;
    }

    &--hasthumb {
      position: relative;
    }

    &--checkbox {
      padding-left: 0.8rem;
      max-width: 3em;
      z-index: 2;
      overflow: visible;
    }

    &--hasthumb#{&}--checkbox {
      padding-left: 1.2rem;
    }

    // Sub-content items
    &__range {
      position: relative;
      display: block;
      background: rgba(#072ba0, 0.2);
      width: 100%;
      height: 4px;
      border-radius: 4px;
      max-width: 8em;

      .range--slide {
        display: block;
        background: var(--colour);
        height: 4px;
        border-radius: 4px;
      }

      .range--hover {
        display: block;
        width: 100%;
        height: auto;
        position: absolute;
        top: -0.8em;
        bottom: -0.8em;
        left: 0;
      }
    }
  }
}
</style>