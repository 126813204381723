import go from '@/mixins/go.js';

/**
 *  Prevent default behaviour
 * 
 */
export default {
  mixins: [go],
  mounted() {
    this.$el.addEventListener('click', this.check);
  },
  beforeUnmount() {
    this.$el.removeEventListener('click', this.check);
  },
  methods: {
    check(e) {
      let link = this.isAnchorTag(e.target);

      // If no link is found, return
      if (!link) return;

      // Visit page
      this.go(e, link.getAttribute('href'), link);
    },
    isAnchorTag(elem) {
      // Loop through path list and check for anchor tags
      while (elem) {
        if (elem && elem instanceof HTMLAnchorElement) {
          return elem;
        }

        // If no match, check parent nodes
        elem = elem.parentNode;
      }

      return false;
    }
  }
};
