<template>
  <label class="form__label" :class="[labelClass, details.classname]">
    {{ details.label }}

    <MultiValues
      type="text"
      :valuesProp="value"
      :required="details.required"
      :pattern="details.pattern"
      :maxlength="details.maxlength"
      class="form__input"
      :class="inputClass"
      @values-updated="updateValue"
    />

    <span v-if="maxlength" class="form__label__maxlength">
      {{ value.length }} of {{ maxlength }}
    </span>
  </label>
</template>

<script>
import defaultData from "./__mixins.js";
import MultiValues from "@/components/ui/MultiValues.vue";

export default {
  name: "MultitagsInput",
  mixins: [defaultData],
  components: {
    MultiValues,
  },
  data() {
    let { value, maxlength } = this.details;

    // Check if value is an array, else default to array
    let validValue = Array.isArray(value);

    // Convert invalid values to array
    if (!validValue) value = [value];

    // Return
    return {
      value,
      maxlength,
    };
  },
  methods: {
    updateValue(e) {
      this.value = e;

      this.updateInput();
    },
  },
};
</script>

<style lang="scss"></style>