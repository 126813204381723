<template>
  <fieldset class="autobuilder__conditions__fieldset" tabindex="-1">
    <legend>Conditions row</legend>

    <input
      required
      aria-label="Associated input name"
      class="autobuilder__conditions__input"
      v-model="input"
      :list="uniqueId"
      placeholder="Target input"
    />

    <datalist :id="uniqueId">
      <option v-for="input of inputKeys" :key="input" :value="input" />
    </datalist>

    <MultiValues :valuesProp="values" @values-updated="updateValues" />

    <button
      type="button"
      class="autobuilder__conditions__delete"
      @click.prevent.stop="removeCondition"
      aria-label="Delete this row"
      title="Delete this row"
    >
      <SvgIcon
        name="cross"
        title="Delete icon"
        class="autobuilder__conditions__delete__icon"
      />
    </button>
  </fieldset>
</template>

<script>
import uniqueId from "@/mixins/unique-id.js";
import MultiValues from "@/components/ui/MultiValues.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";

export default {
  name: "FormConditionsRow",
  mixins: [uniqueId],
  components: {
    SvgIcon,
    MultiValues,
  },
  props: {
    conditionProp: {
      type: Object,
      default: () => {},
    },
    inputKeys: {
      type: Array,
      default: () => [],
    },
    conditionId: {
      type: String,
    },
  },
  data() {
    let { input, values } = this.conditionProp;
    let id = this.uniqueId;

    return {
      input,
      values,
      optionId: `${id}-option-`,
    };
  },
  methods: {
    updateValues(values) {
      this.values = values;

      // Update condition on value changes
      this.updateCondition();
    },
    removeCondition() {
      this.$emit("remove-condition");
    },
    updateCondition() {
      let { input, values, conditionId } = this;

      this.$emit("update-condition", { input, values, conditionId });
    },
  },
  watch: {
    input() {
      this.updateCondition();
    },
  },
};
</script>

<style lang="scss">
.autobuilder__conditions {
  &__fieldset {
    display: grid;
    grid-template-columns: 1fr 1.5fr 2.5em;
    align-items: flex-start;
    grid-gap: 0;
  }

  &__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .multivalue__input {
    border-radius: 0;
  }

  &__delete {
    width: 100%;
    height: 3rem;
    font-size: 1.4em;
    text-align: center;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    color: var(--light-text);
    background: var(--input-bg);
    border-radius: var(--input-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition: color 0.15s;

    &__icon {
      display: block;
      margin: auto;
      width: 0.6em;
      height: 0.6em;
      stroke: currentColor;
      stroke-width: 5px;
      fill: transparent;
      stroke-linejoin: round;
      stroke-linecap: round;
    }

    &:hover {
      color: var(--input-error);
    }
  }
}
</style>