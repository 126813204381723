<template>
  <label
    class="form__label"
    :class="[labelClass, details.classname]"
    v-if="details.options.length"
  >
    {{ details.label }}
    <select
      :required="details.required"
      class="form__input"
      :class="inputClass"
      v-model="value"
      @change="updateInput"
    >
      <option v-if="!details.required" value="">--</option>
      <option
        v-for="(option, index) of details.options"
        :key="index"
        :value="option.value"
        :selected="option.selected"
      >
        {{ option.text }}
      </option>
    </select>
  </label>
</template>

<script>
import defaultData from "./__mixins.js";

export default {
  name: "SelectInput",
  mixins: [defaultData],
  data() {
    let { options, value } = this.detailsProp;

    // If value exists, return
    if (value) return {};

    // Search for any selected options
    let selected = options?.filter((n) => n.selected);

    // Return option
    return {
      value: selected[0]?.value || undefined,
    };
  },
};
</script>

<style lang="scss"></style>