// Type input
export default {
  name: 'maxlength',
  label: 'Max length (0 for no maximum)',
  type: 'number',
  value: 0,
  min: 0,
  step: 1,
  conditions: [
    {
      input: 'type',
      values: ['number', 'multitags']
    }
  ]
};
