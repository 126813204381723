// Type input
export default {
  name: 'sortlist',
  label: 'Lists be sortable using this value?',
  type: 'toggle',
  value: false,
  conditions: [
    {
      input: 'listed',
      values: ['id', 'range', 'text', 'number']
    }
  ]
};
