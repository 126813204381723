<template>
  <label class="form__label" :class="[labelClass, details.classname]">
    {{ details.label }}
    <input
      type="number"
      :placeholder="details.placeholder"
      :required="details.required"
      :pattern="details.pattern"
      :format="details.format"
      :min="details.min"
      :max="details.max"
      :step="details.step"
      class="form__input"
      :class="inputClass"
      @change="updateInput"
      v-model="value"
    />
  </label>
</template>

<script>
import defaultData from "./__mixins.js";

export default {
  name: "NumberInput",
  mixins: [defaultData],
};
</script>

<style lang="scss"></style>