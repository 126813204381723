<template>
  <div class="multivalue">
    <fieldset class="multivalue__form">
      <legend>Add new value</legend>

      <input
        class="multivalue__input"
        :class="{ 'multivalue__input--populated': values.length }"
        @keydown.enter.prevent.stop="addOption"
        ref="input"
        placeholder="Values (press enter to add)"
      />
      <button class="multivalue__add" @click.prevent.stop="addOption">
        Add
      </button>
    </fieldset>

    <ul class="multivalue__list" v-if="values.length">
      <li
        class="multivalue__listitem"
        v-for="(condition, index) of values"
        :key="condition"
        :id="uniqueId + index"
      >
        <span class="multivalue__listitem__text">{{ condition }}</span>
        <button
          @click.prevent.stop="removeOption(index)"
          title="Remove option"
          class="multivalue__listitem__delete"
          :aria-controls="uniqueId + index"
        >
          <SvgIcon
            name="cross"
            title="Delete icon"
            class="multivalue__listitem__delete__icon"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import uniqueId from "@/mixins/unique-id.js";
import SvgIcon from "@/components/ui/SvgIcon.vue";

export default {
  name: "MultiValues",
  props: {
    valuesProp: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [uniqueId],
  components: {
    SvgIcon,
  },
  data() {
    return {
      values: [...this.valuesProp] || [],
    };
  },
  methods: {
    addOption(e) {
      let input = this.$refs.input;

      // Check value isn't empty
      if (!input.value) {
        this.$store.dispatch("toast", {
          error: true,
          content: "You have not entered a value",
          timeout: 3000,
        });

        return;
      }

      // Add input value to values
      this.values.push(input.value);

      // Reset value of input
      input.value = "";

      // Refocus on input
      input.focus();

      // Register update
      this.updateValues();
    },
    removeOption(index = -1) {
      this.values.splice(index, 1);

      // Register update
      this.updateValues();
    },
    updateValues() {
      this.$emit("values-updated", this.values);
    },
  },
};
</script>

<style lang="scss">
.multivalue {
  &__form {
    position: relative;
  }

  &__input {
    padding-right: 4em;
    min-width: 8em;

    &--populated {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__add {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 1.2em;
    background: transparent;
    color: var(--faded-text-darker);

    &:hover {
      background: transparent;
      color: var(--colour);
    }
  }

  &__input:placeholder-shown + &__add {
    visibility: hidden;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0.8em 1.2em 0.6em;
    background: var(--input-bg);
    border-radius: var(--input-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid var(--input-bg-hover);
  }

  &__listitem {
    display: block;
    margin: 0 0.2em 0.2em 0;
    padding: 0.3em 0.7em;
    display: flex;
    align-items: center;
    background: var(--colour);
    color: white;
    border-radius: 2em;
    line-height: 1.2em;

    &__text {
      display: block;
      white-space: nowrap;
      max-width: 6em;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.89em;
    }

    &__delete {
      padding: 0;
      margin: 0 0 0 0.8em;
      background: transparent;
      color: currentColor;

      &__icon {
        display: block;
        margin: auto;
        width: 0.6em;
        height: 0.6em;
        stroke: currentColor;
        stroke-width: 5px;
        fill: transparent;
        stroke-linejoin: round;
        stroke-linecap: round;
      }
    }
  }
}
</style>