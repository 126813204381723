<template>
  <div class="dashboard">
    <section class="dashboard__header">
      <div class="dashboard__header__logo" role="banner">
        <VueLink
          href="/"
          class="dashboard__header__link"
          title="Back to homepage"
        >
          <img
            class="dashboard__header__image"
            src="~@/assets/images/logo.svg"
            alt="Motion CMS"
          />
        </VueLink>
      </div>

      <SearchForm @run-search="search" />

      <DashboardProfile />
    </section>

    <DashboardMenu />

    <div class="dashboard__content">
      <DashboardToolbar @toolbar-edit="edit" @toolbar-save="save" />

      <main
        class="dashboard__content__grid"
        :style="`grid-template-columns: ${layout}`"
      >
        <DashboardContent
          v-for="(segment, i) of modules"
          :key="i"
          :segment="segment"
          :ref="
            (el) => {
              moduleBoxes[i] = el;
            }
          "
        />
      </main>
    </div>

    <footer class="dashboard__footer">
      Copyright &copy; Motion, {{ $options.date }}
    </footer>

    <ModalWindow
      v-if="modal"
      :type="modal.type"
      :content="modal.content"
      :form="modal.form"
      :resolve="modal.resolve"
      :buttons="modal.buttons"
    />
  </div>
</template>

<script>
import DashboardProfile from "@/regions/Profile.vue";
import DashboardMenu from "@/regions/Menu.vue";
import DashboardToolbar from "@/regions/Toolbar.vue";
import DashboardContent from "@/regions/Content.vue";
import SearchForm from "@/components/ui/SearchForm.vue";
import ModalWindow from "@/components/modals/";
import saveContent from "@/mixins/save.js";
import VueLink from "@/router/VueLink.vue";
import { mapState } from "vuex";
import { ref, onBeforeUpdate } from "vue";

export default {
  name: "MotionDashboard",
  components: {
    DashboardProfile,
    DashboardMenu,
    DashboardToolbar,
    DashboardContent,
    SearchForm,
    ModalWindow,
    VueLink,
  },
  computed: mapState({
    modules: (state) => state.content?.modules,
    layout: (state) => state.content?.layout || "1fr",
    modal: (state) => state.modal,
  }),
  mixins: [saveContent],
  methods: {
    search(e) {
      console.log(e);
    },
    edit(e) {
      console.log(e);
    },
    save(e) {
      console.log({
        action: "save",
        content: this.moduleBoxes,
      });
    },
  },
  mounted() {
    this.$options.date = new Date().getFullYear();
  },
  setup() {
    const moduleBoxes = ref([]);

    // make sure to reset the refs before each update
    onBeforeUpdate(() => {
      moduleBoxes.value = [];
    });

    return {
      moduleBoxes,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/sizes";
@import "~@/assets/scss/settings/media";

.dashboard {
  display: grid;
  grid-template-columns: $navbar-width auto;
  grid-row-gap: $guttering;
  align-items: flex-start;
  padding-top: $header-height + $guttering;

  @include bounds($hdscreen) {
    grid-template-columns: $navbar-width-hd auto;
  }

  @include bounds($phablet, $desktop) {
    grid-template-columns: $navbar-width-tablet auto;
  }

  @include bounds(0, $phablet) {
    display: block;
  }

  &__header,
  &__footer {
    grid-column: 1 / span 2;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    z-index: 11;
    padding: 0 $content-padding;
    box-sizing: border-box;

    &__logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: $navbar-width - $content-padding + $guttering;
      box-sizing: border-box;
      padding: 0;

      @include bounds($hdscreen) {
        width: $navbar-width-hd - $content-padding + $guttering;
      }

      @include bounds($phablet, $desktop) {
        width: $navbar-width-tablet - $content-padding + $guttering;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__image {
      display: block;
      width: 143px;
      height: 30px;
    }
  }

  &__content {
    padding: 0 $guttering;

    &__grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: $guttering;
      grid-auto-flow: dense;

      &:empty {
        height: 12em;
        background: url("~@/assets/images/loading.svg") no-repeat center;
        background-size: 3.5em 3.5em;
      }
    }
  }

  &__footer {
    padding: 2rem 0;
    font-size: 0.85rem;
    line-height: 1.4em;
    text-align: center;
    color: var(--light-text);
  }
}
</style>
