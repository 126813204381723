/**
 *  Add overflow to document body
 *
 */
export default {
  data() {
    return {
      overflow: true
    };
  },
  watch: {
    overflow: {
      immediate: true,
      handler(active) {
        let scrollbarWidth = window.innerWidth - document.body.clientWidth + 'px';

        // Toggle scroll: overflow
        document.documentElement.style.overflow = active ? 'hidden' : null;

        // Toggle padding to offset scrollbar removal
        document.body.style.paddingRight = active ? scrollbarWidth : null;
      }
    }
  }
};
