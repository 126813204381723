/**
 *  Check whether authorised
 * 
 */
export default {
  get authorisation() {
    return window.localStorage.getItem('user');
  },
  set authorisation(user = false) {
    if (!user) return;

    // Save token
    window.localStorage.setItem('user', user);
  }
};
