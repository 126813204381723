<template>
  <div
    class="toast"
    aria-atomic="true"
    :class="{'toast--error': error, 'toast--hiding': transitioning}"
    :aria-live="ariaLive"
    :role="ariaRole"
    :hidden="closed"
    @mouseover="removeTimeout"
  >
    <slot></slot>

    <button class="toast__close" title="Close" aria-label="Close" @click="transitionOut">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "ToastPopup",
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      closed: false,
      transitioning: false,
      autoremove: null,
    };
  },
  computed: {
    ariaLive() {
      return this.error ? "assertive" : "polite";
    },
    ariaRole() {
      return this.error ? "alert" : "status";
    },
  },
  methods: {
    transitionOut() {
      // Remove any active timeouts
      this.removeTimeout();

      // Add 'transitioning' class
      this.transitioning = true;

      // Remove after transition is complete
      setTimeout(() => {
        // Set toast to hidden
        this.closed = true;

        // Throw 'remove toast' event
        this.$emit("remove-toast");
      }, 500);
    },
    createTimeout() {
      this.autoremove = setTimeout(() => this.transitionOut(), this.timeout);
    },
    removeTimeout() {
      if (this.autoremove) clearTimeout(this.autoremove);
    },
  },
  created() {
    if (!this.timeout) return;

    // Add timeout (if necessary)
    this.createTimeout();
  },
};
</script>

<style lang="scss">
.toast {
  position: relative;
  display: block;
  max-width: 25rem;
  float: right;
  clear: both;
  box-sizing: border-box;
  padding: 1.2rem 1.5rem;
  background: #70797c;
  color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 3px 5px rgba(#000, 0.1);
  font-size: 1.1em;
  line-height: 1.4em;
  text-align: center;
  pointer-events: auto;
  animation: show_toast 0.4s;
  margin: 0.4em 0 0;

  &--hiding {
    opacity: 0;
    animation: hide_toast 0.15s ease-out;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
  }

  &--success {
    color: white;
    background: #6bbd46;
  }

  &--error {
    color: white;
    background: #e2292c;
  }

  &--warning {
    background: #ee5214;
  }

  &__close {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 1em;
    padding: 0.1em;
    color: rgba(#fff, 0.4);
    transition: color 0.15s;
    font-size: 1.2em;
    cursor: pointer;

    &:hover {
      background: transparent;
      color: #fff;
    }
  }
}

@keyframes show_toast {
  0% {
    opacity: 0;
    transform: translate(0, 2em);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes hide_toast {
  0% {
    opacity: 1;
    transform: none;
    position: static;
  }

  20% {
    opacity: 1;
    transform: scale(1.05);
    position: static;
  }

  100% {
    opacity: 0;
    transform: scale(0.8);
    position: static;
  }
}
</style>
