<template>
  <div class="settings__option__text">
    <label
      class="settings__option__label settings__option__label--text"
      :id="uniqueId"
      v-if="label"
      >{{ label }}</label
    >

    <input
      class="settings__option__text__input"
      :type="type || 'text'"
      :value="value"
      :placeholder="placeholder"
      @change="(e) => updateValue(e.target.value, model)"
    />
  </div>
</template>

<script>
import uniqueId from "@/mixins/unique-id.js";

export default {
  name: "SettingsInput",
  mixins: [uniqueId],
  props: {
    option: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let { label, placeholder, type, model } = this.option;

    return {
      label,
      placeholder,
      type,
      model,
    };
  },
  methods: {
    updateValue(value, model) {
      this.$emit("option-updated", { value, model });
    },
  },
  computed: {
    value() {
      return this.option?.value;
    },
  },
};
</script>

<style lang="scss">
.settings__option {
  &__text {
    &__input {
      border: 1px solid var(--faded-text);
      height: 3em;
      padding: 0 1em;
    }
  }
}
</style>