/**
 *  Common functions for working with arrays
 * 
 */
export default {
  methods: {
    findIndex(arr, key, value) {
      // Get pivot node in array
      let pivot = arr.find(n => n[key] === value);

      // Search index of pivot and return
      return pivot !== undefined ? arr.indexOf(pivot) : -1;
    },
    inRange(value, max, min = 0) {
      if (!max && max !== 0) return true;

      return value < max && value > min;
    }
  }
};
