<template>
  <div class="settings" :hidden="!validOptions.length">
    <button
      class="settings__toggle"
      @click.prevent="toggleModal"
      :aria-controls="uniqueId"
      :aria-expanded="expanded ? 'true' : 'false'"
      aria-label="Show settings modal"
      ref="toggle"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        class="settings__toggle__burger"
      >
        <title>Settings menu burger</title>
        <path
          d="M9 2C9 1.44769 9.44771 1 10 1H12C12.5523 1 13 1.44769 13 2V4C13 4.55231 12.5523 5 12 5H10C9.44771 5 9 4.55231 9 4V2Z M9 10C9 9.44769 9.44771 9 10 9H12C12.5523 9 13 9.44769 13 10V12C13 12.5523 12.5523 13 12 13H10C9.44771 13 9 12.5523 9 12V10Z M10 17C9.44771 17 9 17.4477 9 18V20C9 20.5523 9.44771 21 10 21H12C12.5523 21 13 20.5523 13 20V18C13 17.4477 12.5523 17 12 17H10Z"
        />
      </svg>
    </button>

    <div
      class="settings__modal"
      :class="{ 'settings__modal--narrow': narrow }"
      :hidden="!expanded"
      :id="uniqueId"
      tabindex="-1"
      @keydown.escape.stop="keyboardHideModal"
    >
      <!-- <FocusLock> -->
      <ul class="settings__list">
        <li
          class="settings__listitem"
          v-for="(option, index) of validOptions"
          :key="index"
          :style="{ animationDelay: `${index * 33 + 33}ms` }"
        >
          <component
            :is="option._is"
            :option="option"
            @option-updated="optionUpdated"
            class="settings__option"
          ></component>
        </li>
        <li
          v-if="deletable"
          class="settings__listitem settings__listitem--delete"
          :style="{ animationDelay: `${validOptions.length * 33 + 33}ms` }"
        >
          <button
            class="settings__delete"
            aria-label="Delete this component"
            @click.prevent="deleteComponent"
          >
            Delete
          </button>
        </li>
      </ul>
      <!-- </FocusLock> -->
    </div>
  </div>
</template>

<script>
import clickout from "@/mixins/clickout.js";
import uniqueId from "@/mixins/unique-id.js";
import FocusLock from "vue-focus-lock";

// Option components
import SettingsListingButton from "./settings/ListingButton.vue";
import SettingsRangeButton from "./settings/RangeButton.vue";
import SettingsInput from "./settings/Input.vue";

export default {
  name: "SettingsPopup",
  mixins: [clickout, uniqueId],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  components: {
    SettingsListingButton,
    SettingsRangeButton,
    SettingsInput,
    // FocusLock
  },
  computed: {
    validOptions() {
      return this.options
        .map((option) => {
          let _is = this.getType(option.is);

          return _is ? { ...option, ...{ _is } } : null;
        })
        .filter((n) => n);
    },
  },
  methods: {
    getType(name = null) {
      switch (name) {
        case "listing-button":
          return "SettingsListingButton";
          break;

        case "range-button":
          return "SettingsRangeButton";
          break;

        case "input":
          return "SettingsInput";
          break;

        default:
          return null;
          break;
      }
    },
    toggleModal() {
      if (this.expanded) {
        this.hideModal();

        return;
      }

      this.showModal();
    },
    showModal() {
      this.expanded = true;
    },
    hideModal() {
      this.expanded = false;
    },
    keyboardHideModal() {
      this.hideModal();

      // Focus back on toggle
      this.$refs.toggle.focus();
    },
    clickOutside(e) {
      if (!this.expanded || this.$el.contains(e.target)) return;

      this.hideModal();
    },
    optionUpdated(evt) {
      this.$emit("option-updated", evt);
    },
    deleteComponent() {
      this.$emit("delete-component");
    },
  },
};
</script>

<style lang="scss">
.settings {
  position: relative;

  &__toggle {
    background: transparent;
    color: #849cba;
    padding: 0;
    width: 1.2em;
    height: 1.2em;
    margin: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__burger {
      display: block;
      margin: 0;
      height: 0.89em;
      width: 0.89em;
      stroke: none;
      fill: currentColor;
      transition: fill 0.15s;
    }

    &:hover {
      background: transparent;
      color: #394a5f;
    }
  }

  &__modal {
    position: absolute;
    top: calc(100% + 1em);
    right: -0.8em;
    width: 20em;
    max-width: 80vw;
    background: white;
    color: var(--text);
    border-radius: 0.6em;
    padding: 0.6em 1.2em;
    box-sizing: border-box;
    text-align: left;
    box-shadow: 0 2px 4px -2px rgba(#000, 0.1), 0 4px 25px -5px rgba(#000, 0.1),
      0 0 45px rgba(#000, 0.1);
    z-index: 4;
    animation: fadeIn 0.3s cubic-bezier(0.005, 0.895, 0.45, 0.995);

    &:focus {
      outline: none;
    }

    &:before {
      content: "";
      background: white;
      width: 0.6em;
      height: 0.6em;
      border-top-left-radius: 0.2em;
      position: absolute;
      top: -0.3em;
      right: 1.05em;
      transform: rotate(45deg);
    }

    &--narrow {
      width: 14em;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__listitem {
    padding: 0;
    margin: 0;
    animation: fadeUp 0.2s cubic-bezier(0.005, 0.895, 0.45, 0.995);
    animation-fill-mode: backwards;

    &--delete {
      margin: 0.8em 0 0;
      padding: 0.6em 0;
    }
  }

  &__delete {
    background: var(--input-error);
    color: white;
    margin: 0;
    display: block;
    width: 100%;
    text-align: center;
    padding: 0.6em;

    &:hover {
      background: var(--input-error-hover);
      color: white;
    }
  }

  &__option__label {
    display: block;
    font-size: 0.89em;
    line-height: 1.4em;
    color: var(--faded-text-darker);
    margin: 0 0 0.4em;
  }
}
</style>