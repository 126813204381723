<template>
  <section
    :style="gridsize"
    class="content"
    :class="`content--${segment.type}`"
  >
    <component :is="segmentType" :content="segment.content" />
  </section>
</template>

<script>
// Key components
import DashboardListings from "@/components/listings/";
import DashboardFormbuilder from "@/components/formbuilder/";
import DashboardFormviewer from "@/components/formviewer/";

// Additional components
import DashboardHtml from "@/components/modules/html/";
import DashboardImage from "@/components/modules/image/";
import DashboardMarkdown from "@/components/modules/markdown/";
import DashboardGraph from "@/components/modules/graph/";
import DashboardCalendar from "@/components/modules/calendar/";

export default {
  name: "DashboardContent",
  props: {
    segment: {
      type: Object,
    },
  },
  components: {
    DashboardHtml,
    DashboardMarkdown,
    DashboardImage,
    DashboardGraph,
    DashboardCalendar,
    DashboardListings,
    DashboardFormbuilder,
    DashboardFormviewer,
  },
  computed: {
    gridsize() {
      let { rows, columns } = this.segment;

      return `grid-row: span ${rows || 1}; grid-column: span ${columns || 1}`;
    },
    segmentType() {
      let type = this.segment?.type;
      let component = "dashboard-html";

      // If no type is supplied, return default
      if (!type) return component;

      // Switch component type
      switch (type) {
        case "markdown":
          component = "dashboard-markdown";
          break;

        case "graph":
          component = "dashboard-graph";
          break;

        case "image":
          component = "dashboard-image";
          break;

        case "listings":
          component = "dashboard-listings";
          break;

        case "calendar":
          component = "dashboard-calendar";
          break;

        case "builder":
          component = "dashboard-formbuilder";
          break;

        case "form":
          component = "dashboard-formviewer";
          break;

        default:
          break;
      }

      return component;
    },
  },
};
</script>

<style lang='scss'>
@import "~@/assets/scss/settings/extends.scss";

.content {
  @extend %shadowbox;
  @extend %radiusbox;
  @extend %paddingbox;

  text-align: left;
  min-height: 10em;
  overflow: visible;
  flex: 1;

  &:empty {
    display: none;
  }

  &--auto-overflow {
    overflow: auto;
  }

  &--no-overflow {
    overflow: hidden;
  }
}

// Banner image content
.bannerimage {
  padding: 0;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    min-height: 100%;
    object-fit: contain;
  }
}
</style>