// Type input
export default {
  name: 'max',
  label: 'Maximum',
  type: 'number',
  value: '',
  placeholder: '0',
  step: 'any',
  conditions: [
    {
      input: 'type',
      values: ['number']
    }
  ],
  allconditions: false
};
