/**
 *  Generate unique IDs
 * 
 */
export default {
  data() {
    return {
      uniqueId: this.generateId()
    };
  },
  methods: {
    generateId(len = 11) {
      let prefix = this.$options.name?.toLowerCase() || 'element';

      return `${prefix}-${Math.random().toString(36).substr(2, len)}`;
    }
  }
};
