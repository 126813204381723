<template>
  <label
    role="checkbox"
    class="checkbox"
    :class="{
      'checkbox--active': isChecked,
      'checkbox--indeterminate': isIndeterminate,
      'checkbox--rtl': rtl,
    }"
    :aria-checked="isChecked ? 'true' : 'false'"
    tabindex="0"
    @keyup.enter="keyCheck"
    @keyup.space="keyCheck"
    @click="mouseClick"
  >
    <svg
      class="checkbox__tickbox"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      :disabled="isDisabled"
    >
      <rect
        x="2.5"
        y="2.5"
        width="17"
        height="17"
        rx="5"
        ry="5"
        class="checkbox__tickbox__box"
      />
      <path
        v-if="disabled || indeterminate"
        d="M14 11l-6 0"
        class="checkbox__tickbox__tick"
      />
      <path
        v-else
        d="M16 7l-6.5 7.5-3.5-3.5"
        stroke="#fff"
        class="checkbox__tickbox__tick"
      />
    </svg>
    {{ label }}
  </label>
</template>

<script>
export default {
  name: "InputCheckbox",
  props: {
    label: String,
    checked: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isChecked: this.checked,
      isDisabled: this.disabled ? "disabled" : null,
      isIndeterminate: this.indeterminate,
    };
  },
  methods: {
    keyCheck(e) {
      if (this.disabled) {
        e.stopImmediatePropagation();

        return;
      }

      // Prevent (e.g.) spacebar 'skip'
      e.preventDefault();

      // Toggle checkbox
      this.toggleCheckbox();
    },
    mouseClick(e) {
      if (this.disabled) {
        e.stopImmediatePropagation();

        return;
      }

      this.toggleCheckbox();
    },
    toggleCheckbox() {
      this.isChecked = Boolean(!this.isChecked);

      // Disabled indeterminate on any toggle
      this.isIndeterminate = false;

      // Emit event
      this.$emit("update:modelValue", this.isChecked);
      this.$emit("input", this.isChecked);
      this.$emit("change", this.isChecked);
    },
  },
  watch: {
    checked() {
      this.isChecked = this.checked;

      if (this.isChecked === true) this.isIndeterminate = false;
    },
    indeterminate() {
      this.isIndeterminate = this.indeterminate;

      if (this.isIndeterminate === true) this.isChecked = false;
    },
  },
};
</script>

<style lang="scss">
$size: 1.35em;

.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  line-height: $size;

  &__tickbox {
    display: block;
    width: $size;
    min-width: $size;
    height: $size;
    margin: 0 0.6em 0 0;
    padding: 0;

    &__tick {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: transparent;
      stroke-dasharray: 20;
      stroke-dashoffset: 20;
      transition: stroke-dashoffset 0.12s cubic-bezier(0, 0.6, 0.8, 0) 0.05s;
    }

    &__box {
      stroke: rgba(#072ba0, 0.15);
      stroke-width: 2;
      fill: none;
      transition: stroke 0.15s, fill 0.15s;
    }
  }

  &[disabled] {
    pointer-events: none;
    filter: grayscale(100%) brightness(170%);
  }

  &:active &__tickbox {
    transform: scale(0.93);
  }

  &:hover &__tickbox:not([disabled="true"]) &__tickbox__box {
    stroke: var(--colour);
  }

  &--active &__tickbox__box,
  &--indeterminate &__tickbox__box {
    fill: var(--colour);
    stroke: var(--colour);
  }

  &--active &__tickbox__tick,
  &--indeterminate &__tickbox__tick {
    stroke-dashoffset: 40;
    stroke: #fff;
  }

  &--rtl {
    justify-content: space-between;
  }

  &--rtl &__tickbox {
    order: 2;
    margin: 0 0 0 0.6em;
  }
}
</style>
