// Type input
export default {
  name: 'pattern',
  label: 'Regex pattern',
  type: 'text',
  value: '',
  placeholder: 'e.g. [A-Za-z]',
  conditions: [
    {
      input: 'type',
      values: ['text']
    }
  ]
};
