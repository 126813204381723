<template>
  <div class="htmlbox" v-html="content" />
</template>

<script>
import linkOverride from "@/mixins/link.js";

export default {
  name: "DashboardHtml",
  mixins: [linkOverride],
  props: {
    content: {
      type: String,
      default: "<p>No content provided</p>",
    },
  },
};
</script>

<style lang="scss">
.htmlbox {
  // ...
}
</style>